import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import TestSubmissionStartComponent from "../../components/testSubmission/startComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";


class TestSubmissionStartContainer extends Component {

    /**
     *  Initializer
     *------------------------------------------------------------
     */

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
        };
        this.onClick = this.onClick.bind(this);
    }

    /**
     *  Utility
     *------------------------------------------------------------
     */


    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidUpdate(nextProps) {
        const { isReadyToBegin, testInitializerErrorMessage } = nextProps;
        if (isReadyToBegin != this.state.isReadyToBegin) {
            this.setState({
                isReadyToBegin: isReadyToBegin,
            });
        }
        if (testInitializerErrorMessage !== "" && isEmpty(this.state.errors)) {
            this.setState({
                errors: {
                    nonFieldError: testInitializerErrorMessage,
                },
            });
        }
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    onClick(event) {
        event.preventDefault();
        this.props.history.push("/submission/agreement");
    }

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const { errors, isReadyToBegin } = this.state;

        return (
            <TestSubmissionStartComponent
                testSubmission={this.props.testSubmission}
                test={this.props.test}
                isLoading={isReadyToBegin}
                errors={errors}
                onClick={this.onClick}
            />
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testSubmission: store.testSubmissionState,
        test: store.testState,
    };
}

const mapDispatchToProps = dispatch => {
    return {}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSubmissionStartContainer);
