import React from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Alert from 'react-bootstrap/Alert';
import { Offline, Online } from "react-detect-offline";
import Moment from 'react-moment';
import 'moment-timezone';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faExclamationCircle, faTimesCircle, faCheckCircle, faFileContract, faExternalLinkAlt, faLock } from '@fortawesome/free-solid-svg-icons';

import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";


export default function TestSubmissionAgreementComponent(props){
    const { isDisabled, onCheckboxChange, testSubmission, test, onClick, isLoading } = props;
    return(
        <Container>
            <TopNavigationComponent
                title={testSubmission && testSubmission.test && testSubmission.test.name}
                year={testSubmission && testSubmission.test && testSubmission.test.year}
            />
            <div style={{marginTop: 120}} />
            <Offline>
                <Alert key={"offline-banner"} variant="warning">
                    {/* <strong>Offline Mode:</strong>&nbsp;No internet detected. You won't be able to submit your test until you regain an internet connection. */ }
                    <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                </Alert>
            </Offline>

            <Jumbotron>
                <h1><FontAwesomeIcon icon={faFileContract} />&nbsp;Condiciones de aplicación</h1>
                <p>Recuerda que una vez inicies la prueba empezará a correr tu tiempo de aplicación.</p>
                <p>Si abandonas la prueba tomaremos como completado tu intento y no podrás volver a realizarla.</p>
                <p>Ten presente que una vez se cumpla el conteo regresivo se cerrará la prueba y las respuestas serán enviadas a nuestra base de datos.</p>
                <p><a href="https://miltonochoa.com.co/web/wp-content/uploads/2020/07/Manual-presentaci%C3%B3n.pdf" target="_blank">Ver manual de usuario&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a>.</p>
                <Form>

                <div key={`inline-checkbox`} className="mb-3">
                    <Form.Check
                        type={`checkbox`}
                        id={`default-checkbox`}
                        label={`Acepto Condiciones`}
                        onChange={onCheckboxChange}
                    />
                    </div>
                </Form>
            <p>
            <Offline>
                <Button size="lg" variant="warning" disabled={true} block><FontAwesomeIcon icon={faLock} />&nbsp;Sin conexión a Internet</Button>
            </Offline>
            <Online>
                <Button size="lg" onClick={(event)=>{onClick(event)}} variant="success" disabled={isDisabled} block><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Aceptar</Button>
            </Online>
            </p>
            <p>
              <Button size="lg" href="https://www.miltonochoa.com" variant="danger" block><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Cancelar</Button>
            </p>
          </Jumbotron>


            <Footer />
        </Container>
    );
}
