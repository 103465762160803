import moment from 'moment';
import 'moment-timezone';


/**
 *  Function will get the current date/time and convert it to be timezone
 *  aware for our student's timezone and then return the `string` representation
 *  of it.
 */
export function getAwareNowDateTimeString(timezone) {
    let nowMoment = moment();
    nowMoment.tz(timezone);
    return nowMoment.format("YYYY-MM-DD HH:mm:ss");
}
