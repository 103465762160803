import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import CardDeck from 'react-bootstrap/CardDeck';
import Moment from 'react-moment';
import 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { Offline, Online } from "react-detect-offline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faClock, faLink, faUserCircle, faBook, faReceipt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
    // TEST_SUBMISSION_PENDING_STATE,
    // TEST_SUBMISSION_IN_PROGRESS_STATE,
    // TEST_SUBMISSION_BREAK_STATE,
    TEST_SUBMISSION_COMPLETED_STATE
} from "../../constants/states";
import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";
import ReceiptPdfDocumentComponent from "./receiptPdfDocumentComponent";


// Import image for the button //
// CSS code from Colombian team to match it with our design //

export default function TestSubmissionCompleteComponent(props) {
    const {
        testSubmission, errors, isLoading, onBackToMainPageClick, test, student,
        session, name, type, onDownloadReceiptClick, receipt, status, elapsedTimeInMinutes,
        isLate, pdfReceiptFilename
    } = props;
    return(
      <Container>
          <TopNavigationComponent
              title={testSubmission && testSubmission.test && testSubmission.test.name}
              year={testSubmission && testSubmission.test && testSubmission.test.year}
          />
          <Offline>
              <Row>
                  <Col>
                      <Alert key={"offline-banner"} variant="warning">
                          {/* <strong>Offline Mode:</strong>&nbsp;No internet detected. You won't be able to submit your test until you regain an internet connection. */ }
                          <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                      </Alert>
                  </Col>
              </Row>
          </Offline>
          {isLoading &&
              <Row>
                  <Col>
                  <Alert key={"loading-banner"} variant="secondary">
                  Loading please wait ...
                  </Alert>
                  </Col>
              </Row>
          }
          {isEmpty(errors) == false &&
              <Row>
                  <Col>
                  <Alert key={"loading-banner2"} variant="danger">
                  {errors["nonFieldError"]}
                  </Alert>
                  </Col>
              </Row>
          }

          {/* Title: Complete or Abandoned State */}
          {status === TEST_SUBMISSION_COMPLETED_STATE
              ? <Row>
                  <Col><br /><br /><br /><br /><br /></Col>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                    {/* On-time or Late */}
                    {isLate === true
                        ? <h3>¡Tiempo Agotado!</h3>
                        : <h3>¡Felicitaciones!</h3>
                    }
                  </Col>
              </Row>
              : <Row>
                  <Col><br /><br /><br /><br /><br /></Col>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                    <h3>Prueba Cerrada</h3>
                  </Col>
              </Row>
          }

          {/* Description: Complete or Abandoned State */}
          {status === TEST_SUBMISSION_COMPLETED_STATE
              ? <Row>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                    {/* On-time or Late */}
                    {isLate === true
                        ? <h3>Prueba Finalizada con Tiempo Agotado</h3>
                        : <h3>Prueba Finalizada</h3>
                    }
                  </Col>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                      <a className="text-success">Duración: {elapsedTimeInMinutes}</a>
                  </Col>
              </Row>
              : <Row>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                    <h3>Encontramos que la prueba fue respondida inadecuadamente. Comunícate con tu institución educativa</h3>
                  </Col>
                  <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                      <a className="text-success">Duración: {elapsedTimeInMinutes}</a>
                  </Col>
              </Row>
          }


          <br />
          <Row>
              <Col>
                  <CardDeck>
                      <Card className="card card-outtro">
                        <Card.Body className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <Card.Title className="text-primary text-card-header"><h4><FontAwesomeIcon icon={faUserCircle} />&nbsp;{testSubmission && testSubmission.student && testSubmission.student.name}</h4></Card.Title>
                          <Card.Text style={{padding:"18.8px",marginLeft:"10px"}}>
                              <a className="text-black">Usuario: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.id}</a></a>
                              <br /><br />
                              <a className="text-black">Grado: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.grade}</a></a>
                              <br /><br />
                              <a className="text-black">Colegio: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.collegeCode}</a></a>
                              <br /><br />
                              <a className="text-black">Ciudad: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.city}</a></a>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                      <Card  className="card card-outtro">
                          <Card.Body className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <Card.Title className="text-primary text-card-header"><h4><FontAwesomeIcon icon={faBook} />&nbsp;{testSubmission && testSubmission.test && testSubmission.test.name}</h4></Card.Title>
                              <Card.Text style={{padding:"18.8px",marginLeft:"10px"}}>
                                  <a className="text-black">Nro Sesiones: <a className="text-primary">{testSubmission.test.numberOfSessions}</a></a> {/* not sure if this is correct as unique session */}
                                  <br /><br />
                                  <a className="text-black">Duración: <a className="text-primary">{testSubmission && testSubmission.test && testSubmission.test.durationInMinutes} minutos</a></a>
                                  <br /><br />
                                  <a className="text-black">Nro Preguntas: <a className="text-primary">{testSubmission && testSubmission.test && testSubmission.test.numberOfQuestions}</a></a>
                                  <br /><br />
                                  <a className="text-black">Fecha Incio: <a className="text-primary">
                                      {testSubmission && testSubmission.test && testSubmission.test.dates.openAt &&
                                          <Moment tz={testSubmission.test.dates.timezone} format="YYYY/MM/DD">
                                              {testSubmission.test.dates.openAt}
                                          </Moment>
                                      }
                                  </a></a>
                                  <br />
                                  <a className="text-black">Fecha Fin: <a className="text-primary">
                                      {testSubmission && testSubmission.test && testSubmission.test.dates.openAt &&
                                          <Moment tz={testSubmission.test.dates.timezone} format="YYYY/MM/DD">
                                              {testSubmission.test.dates.endAt}
                                          </Moment>
                                      }
                                  </a></a>
                              </Card.Text>
                        </Card.Body>
                      </Card>

                      {/*
                          Developers Note:
                          (1) If there was no test loaded then we need to restrict the PDF generation GUI.
                          (2) If a test was not loaded, the reason is because a student loaded a test which
                              was already submitted.
                          (3) We render the PDF `on-the-fly` using the `react-pdf` library by utilizing
                              the `PDFDownloadLink` component.
                      */}
                      {receipt.test &&
                          <Card className="card card-outtro">
                            <Card.Body className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <Card.Title className="text-primary text-card-header"><h4><FontAwesomeIcon icon={faReceipt} />&nbsp;¡Certificado!</h4></Card.Title>
                              <Card.Text style={{padding:"18.8px",marginLeft:"10px"}}>
                             Descarga el certificado de aplicación para tus registros personales. Si tienes algún inconveniente puedes enviarlo a nuestro correo <a href="mailto:atencionalcliente@ceinfes.com" target="_blank">atencionalcliente@ceinfes.com&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} /></a>
                              </Card.Text>
                            </Card.Body>
                            <Card.Body className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                              <PDFDownloadLink
                                  document={
                                      <ReceiptPdfDocumentComponent data={receipt} />
                                  }
                                  fileName={pdfReceiptFilename}
                                  className="btn btn-primary btn-lg btn-block">
                              {({ blob, url, loading, error }) =>
                                loading ? "-" : <span><FontAwesomeIcon icon={faDownload} />&nbsp;Certificado</span>
                              }
                              </PDFDownloadLink>

                            </Card.Body>
                          </Card>
                      }
                  </CardDeck>
              </Col>
          </Row>
          <Row>
             <Online>
             {isEmpty(errors)
                 ? <Button
                   disabled={isLoading}
                   className="m-3 p-3 btn btn-success"
                   size="md"
                   onClick={event=>onBackToMainPageClick(event)}
                   block><span><FontAwesomeIcon icon={faLink} />&nbsp;Visita www.miltonochoa.com</span></Button>
                 : <Button
                   disabled={isLoading}
                   className="m-3 p-3 btn btn-warning"
                   size="md"
                   onClick={event=>onBackToMainPageClick(event)}
                   block>Re-try submitting test</Button>
             }
             </Online>
          </Row>
          <Footer />
      </Container>
      );
  }
