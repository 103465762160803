import React, { Component } from "react";

import IndexComponent from "../../components/general/indexComponent";


export default class IndexContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <IndexComponent
            />
        );
    }
}
