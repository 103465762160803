import { MARKINGCLOUD_FRONTEND_VERSION } from "../constants/version";


/**
 *  Function will return metrics pertaining to the browser.
 */
export default function getBrowserMetrics() {
    let browserMetrics = {};
    try {
        browserMetrics = {
            "appCodeName": navigator.appCodeName,
            "appName": navigator.appName,
            "appVersion": navigator.appVersion,
            "cookieEnabled": navigator.cookieEnabled,
            "language": navigator.language,
            "platform": navigator.platform,
            "product": navigator.product,
            "userAgent": navigator.userAgent,
            "screenAvailHeight": window.screen.availHeight,
            "screenAvailWidth": window.screen.availWidth,
            "screenColorDepth": window.screen.colorDepth,
            "screenHeight": window.screen.height,
            "screenWidth": window.screen.width,
            "frontendVersion": MARKINGCLOUD_FRONTEND_VERSION, // Helps confirm clear cache bugs.
        };
    } catch(e) {
        browserMetrics = {"errorMessage": e.message,};
    }
    return browserMetrics;
}
