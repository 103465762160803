import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import CardDeck from 'react-bootstrap/CardDeck';
import Moment from 'react-moment';
import 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faClock, faLink, faUserCircle, faBook, faReceipt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ScaleLoader } from 'react-spinners';
import Jumbotron from 'react-bootstrap/Jumbotron';

import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";


// Import image for the button //
// CSS code from Colombian team to match it with our design //

export default function TestSubmissionCompleteSendingOfflineComponent(props) {
    const {
        testSubmission, errors, isLoading, test, student,
        session, name, type, status
    } = props;
    return(
      <Container>
              <TopNavigationComponent
                  title={testSubmission && testSubmission.test && testSubmission.test.name}
                  year={testSubmission && testSubmission.test && testSubmission.test.year}
              />
              <div style={{marginTop: 120}} />
              <Jumbotron>
                  <h1>Modo sin conexión</h1>
                  <p>
                  En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                  </p>
                  <p>
                  <Button variant="primary" href="https://www.miltonochoa.com">Volver a www.miltonochoa.com</Button>
                  </p>
              </Jumbotron>
              <Footer />
      </Container>
      );
  }
