import React, { Component } from "react";
import { connect } from 'react-redux';
import isEmpty from "lodash/isEmpty";

import TestSubmissionCompleteComponent from "../../components/testSubmission/completeComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import getBrowserMetrics from '../../helpers/browserMetricsHelper';


class TestSubmissionCompleteContainer extends Component {
    constructor(props) {
        super(props);
        const evaluatorStore = getEvaluatorStoreInstance();

        // For diagnostic purposes, attach some browser details to help the
        // programmers diagnose problems pertaining to test submissions.
        let browserMetrics = getBrowserMetrics();

        // The following code will generate the output data which will be loaded
        // into the PDF receipt generator for our PDF creation document.
        const receipt = evaluatorStore.getReceipt();
        localStorage.setItem("MCL_RECEIPT", JSON.stringify(receipt)); // Useful to store temporarily.

        this.state = {
            isLoading: evaluatorStore.getWasSubmittedToAPI() === false,
            errors: {},
            evaluatorStore: evaluatorStore,
            browserMetrics: browserMetrics,
            receipt: receipt,
        };

        // this.onCollegeNameChange = this.onCollegeNameChange.bind(this);
        this.onDownloadReceiptClick = this.onDownloadReceiptClick.bind(this);
        this.onBackToMainPageClick = this.onBackToMainPageClick.bind(this);
    }

    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    onBackToMainPageClick(event) {
        if (isEmpty(this.state.errors)) {
            // window.location.href="https://miltonochoa.com.co";
            window.location.href="https://www.miltonochoa.com";
        } else {
            window.location.href = "/submission/completed";
        }
    }

    /**
     *  Function will take the submission data, encode it in base64 string,
     *  and create a text file for the user to download for their archiving
     *  purposes.
     */
    onDownloadReceiptClick(event){
        event.preventDefault();

        const base64String = this.state.evaluatorStore.getBase64ReceiptString();

        // Special thanks to:
        // "Text File Download in React" via https://medium.com/@kayathiri/text-file-download-in-react-a8b28a580c0d
        const element = document.createElement("a");
        const file = new Blob([base64String], {type: 'text/plain;charset=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = "receipt.txt";
        document.body.appendChild(element);
        element.click();
    }

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const { evaluatorStore, errors, isLoading, receipt } = this.state;
        console.log();
        return (
            <TestSubmissionCompleteComponent
                receipt={receipt}
                errors={errors}
                isLoading={isLoading}
                elapsedTimeInMinutes={evaluatorStore.getElapsedTimeInMinutes()}
                isLate={evaluatorStore.getIsLate()}
                testSubmission={evaluatorStore._testSubmission}
                test={evaluatorStore._testSubmission.test}
                student={evaluatorStore._testSubmission.student}
                session={evaluatorStore._session }
                name={evaluatorStore._testSubmission.test.name}
                type={evaluatorStore._testSubmission.test.type}
                status={evaluatorStore.getStatus()}
                onDownloadReceiptClick={ this.onDownloadReceiptClick }
                onBackToMainPageClick = { this.onBackToMainPageClick }
                pdfReceiptFilename={`${this.props.testSubmissionObj.test.description}.pdf`}
            />
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testObj: store.testState,
        testSubmissionObj: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSubmissionCompleteContainer);
