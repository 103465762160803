import React from "react";


export default function BadRequest400Component({ errorId, errorMessage}) {
    return(
        <div>
          <h1>400 Bad Request</h1>
          <p>{errorMessage}</p>

          <a href="mailto:reinel.ochoa@ceinfes.com">IT Support Email</a>
          <br /><br />

          <a href="/">Back</a>
        </div>

    );
}
