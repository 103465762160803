import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import {
    TEST_SUBMISSION_PENDING_STATE,
    TEST_SUBMISSION_IN_PROGRESS_STATE,
    TEST_SUBMISSION_BREAK_STATE,
    TEST_SUBMISSION_COMPLETED_STATE
} from "../constants/states";
import getEvaluatorStoreInstance from "../dataStores/evaluatorStore";


/**
 *  Higher-order component (HOC) designed to look at the URL we are on, look
 *  at the status of the `TestSumbission` and enforce the proper app redireciton.
 */
export default function (ComposedComponent) {
    class EvaluatorStoreRedirector extends React.Component {
        constructor(props) {
            super(props);
            this.status = {};
        }

        /**
         *  Utility
         *------------------------------------------------------------
         */


        /**
         *  Component Life-cycle Management
         *------------------------------------------------------------
         */

        componentDidMount() {}

        componentWillUnmount() {}

        /**
         *  API callback functions
         *------------------------------------------------------------
         */

        /**
         *  Event handling functions
         *------------------------------------------------------------
         */

        /**
         *  Main render function
         *------------------------------------------------------------
         */

        render() {
            const { path } = this.props.match;
            const instance = getEvaluatorStoreInstance();
            const status = instance.getStatus();

            if (path === "/submission/start" || path === "/submission/agreement") {
                if (status === TEST_SUBMISSION_PENDING_STATE) {
                    // Do nothing.
                }
                if (status === TEST_SUBMISSION_IN_PROGRESS_STATE) {
                    console.log("withRedirector | sending to question page\n\n");
                    return <Redirect to={"/submission/question"} />;
                }
                if (status === TEST_SUBMISSION_BREAK_STATE) {
                    console.log("withRedirector | sending to break page\n\n");
                    return <Redirect to={"/submission/break"} />;
                }
                if (status === TEST_SUBMISSION_COMPLETED_STATE) {
                    console.log("withRedirector | sending to complete page\n\n");
                    return <Redirect to={"/submission/completed"} />;
                }
            }
            else if (path === "/submission/question") {
                if (status === TEST_SUBMISSION_PENDING_STATE) {
                    console.log("withRedirector | sending to start page\n\n");
                    return <Redirect to={"/submission/start"} />;
                }
                if (status === TEST_SUBMISSION_IN_PROGRESS_STATE) {
                    // Do nothing.
                }
                if (status === TEST_SUBMISSION_BREAK_STATE) {
                    console.log("withRedirector | sending to break page\n\n");
                    return <Redirect to={"/submission/break"} />;
                }
                if (status === TEST_SUBMISSION_COMPLETED_STATE) {
                    console.log("withRedirector | sending to complete page\n\n");
                    return <Redirect to={"/submission/completed"} />;
                }
            }
            else if (path === "/submission/break") {
                if (status === TEST_SUBMISSION_PENDING_STATE) {
                    console.log("withRedirector | sending to start page\n\n");
                    return <Redirect to={"/submission/start"} />;
                }
                if (status === TEST_SUBMISSION_IN_PROGRESS_STATE) {
                    console.log("withRedirector | sending to question page\n\n");
                    return <Redirect to={"/submission/question"} />;
                }
                if (status === TEST_SUBMISSION_BREAK_STATE) {
                    // Do nothing.
                }
                if (status === TEST_SUBMISSION_COMPLETED_STATE) {
                    console.log("withRedirector | sending to complete page\n\n");
                    return <Redirect to={"/submission/completed"} />;
                }
            }
            else if (path === "/submission/completed") {
                if (status === TEST_SUBMISSION_PENDING_STATE) {
                    console.log("withRedirector | sending to start page\n\n");
                    return <Redirect to={"/submission/start"} />;
                }
                if (status === TEST_SUBMISSION_IN_PROGRESS_STATE) {
                    console.log("withRedirector | sending to question page\n\n");
                    return <Redirect to={"/submission/question"} />;
                }
                if (status === TEST_SUBMISSION_BREAK_STATE) {
                    console.log("withRedirector | sending to break page\n\n");
                    return <Redirect to={"/submission/break"} />;
                }
                if (status === TEST_SUBMISSION_COMPLETED_STATE) {
                    // Do nothing.
                }
            }

            // If do nothing then we go here.
            return (
                <div>
                    <ComposedComponent
                        {...this.props}
                    />
                </div>
            );
        }
    } // end of CLASS.

    const mapStateToProps = function(store) {
        return {
            user: store.userState,
            testSubmission: store.testSubmissionState,
        };
    }

    const mapDispatchToProps = null;

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(EvaluatorStoreRedirector);

} // end of HOC.
