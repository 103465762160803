import React, { Component } from "react";

import ServerError500Component from "../../components/general/serverError500Component";


export default class ServerError500Container extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ServerError500Component />
        );
    }
}
