/**
 *  THE PURPOSE OF THIS FILE IS TO ASYNCRHONOUSLY DOWNLOAD THE TEST FROM THE
 *  API WEB-SERVER AND ATTACH IT TO OUR `EVALUATOR STORE`. ONCE THE TEST WAS
 *  DOWNLOADED SUCCESSFULLY AND LOADED UP SUCCESSFULLY INTO OUR STORE, WE WILL
 *  RETURN A SUCCESS MESSAGE TO ALL THE COMPONENTS THAT HAVE THIS HOC ATTACHED.
 *
 *  CURRENTLY THIS HOC IS FOR `STARTCONTAINER`.
 */
import React from 'react';
import { connect } from 'react-redux';

import { pullTest } from "../actions/testActions";
import getEvaluatorStoreInstance from "../dataStores/evaluatorStore";
import { preloadImagesFromTest } from "../helpers/preloadImageHelper";

/**
 *  The purpose of this higher order function is to download the `Test` data
 *  from the API and have it available due to `redux`.
 */
export default function (ComposedComponent) {
    class TestInitializer extends React.Component {
        constructor(props) {
            super(props);
            const evaluatorStore = getEvaluatorStoreInstance();
            this.state = {
                isReadyToBegin: evaluatorStore.isReadyToBegin(),
                testSubmissionUuid: evaluatorStore.getTestSubmissionUuid(),
                errorMessage: "",
            };
            this.onGETSuccessCallback = this.onGETSuccessCallback.bind(this);
            this.onGETFailedCallback = this.onGETFailedCallback.bind(this);
        }

        /**
         *  Utility
         *------------------------------------------------------------
         */

        /**
         *  Component Life-cycle Management
         *------------------------------------------------------------
         */

        componentDidMount() {
            if (this.state.isReadyToBegin === false) {
                this.props.pullTest(
                    this.state.testSubmissionUuid,
                    this.onGETSuccessCallback,
                    this.onGETFailedCallback
                );
            }
        }

        componentWillUnmount() {}

        /**
         *  API callback functions
         *------------------------------------------------------------
         */

        onGETSuccessCallback(test) {
            console.log("TestInitializer | onGETSuccessCallback | Log | Response:");
            console.log(test);
            console.log("\n\n");

            /*
             *  Developers Note:
             *  To help increase performance and make image resources available
             *  when user is offline, we will preload the images from our test
             *  before the user begins.
             */
            preloadImagesFromTest(test);

            const evaluatorStore = getEvaluatorStoreInstance();
            evaluatorStore.setTest(test);
            console.log("TestInitializer | onGETSuccessCallback | Info: Test was set for evaluator and is ready to begin.\n\n");
            this.setState({
                isReadyToBegin: evaluatorStore.isReadyToBegin(),
                errorMessage: "",
            });
        }

        onGETFailedCallback(errors) {
            console.log("TestInitializer | onGETFailedCallback |");
            console.log(errors, "\n\n");
            this.setState({
                isReadyToBegin: false,
                errorMessage: "Network connection error occured",
            })
        }

        /**
         *  Event handling functions
         *------------------------------------------------------------


        /**
         *  Main render function
         *------------------------------------------------------------
         */

        render() {
            const { isReadyToBegin, errorMessage } = this.state;

            return (
                <div>
                    <ComposedComponent
                        isReadyToBegin={isReadyToBegin}
                        testInitializerErrorMessage={errorMessage}
                        {...this.props}
                    />
                </div>
            );
        }
    } // end of CLASS.

    const mapStateToProps = function(store) {
        return {
            testSubmission: store.testSubmissionState,
            test: store.testState
        };
    }

    const mapDispatchToProps = dispatch => {
        return {
            pullTest: (slug, onGETSuccessCallback, onGETFailedCallback) => {
                dispatch(pullTest(slug, onGETSuccessCallback, onGETFailedCallback))
            },
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(TestInitializer);

} // end of HOC.
