import React from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Alert from 'react-bootstrap/Alert';
import { Offline, Online } from "react-detect-offline";
import Moment from 'react-moment';
import 'moment-timezone';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faBullhorn, faUserCircle, faBook } from '@fortawesome/free-solid-svg-icons';

import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";


export default function TestSubmissionStartComponent(props){
    const { testSubmission, test, onClick, isLoading } = props;
    return(
        <Container>
            <TopNavigationComponent
                title={testSubmission && testSubmission.test && testSubmission.test.name}
                year={testSubmission && testSubmission.test && testSubmission.test.year}
            />
            <div style={{marginTop: 120}} />
            <Offline>
                <Alert key={"offline-banner"} variant="warning">
                    {/* <strong>Offline Mode:</strong>&nbsp;No internet detected. You won't be able to submit your test until you regain an internet connection. */ }
                    <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                </Alert>
            </Offline>
            {isLoading &&
                <Alert key={"loading-banner"} variant="secondary">
                Loading please wait ...
                </Alert>
            }
            <CardDeck>
                <Card className="alert-info">
                    <Card.Body className="alert" >
                        <blockquote>
                            <h1>
                            <FontAwesomeIcon icon={faBullhorn} />&nbsp;¿Estás seguro de iniciar?
                            </h1>
                            <h4>
                            Ten presente que una vez inicies empezará a correr el tiempo y deberás finalizar la prueba.
                            </h4>
                            <h4>
                            Si abandonas se dará por cerrado el intento y no podrás aplicar nuevamente
                            </h4>
                        </blockquote>
                    </Card.Body>
                </Card>
            </CardDeck>
            <CardDeck>
                <Card className="mt-4">
                    <Card.Body className="d-flex justify-content-center align-items-center">
                        <img alt="Test Cover" src={testSubmission && testSubmission.test && testSubmission.test.imageUrl} width="255.2" height="280.18" />
                    </Card.Body>
                </Card>
                <Card className="form-group mt-4">
                    <Card.Body className="p-4">
                        <Card.Title className="text-primary text-card-header"><h4><FontAwesomeIcon icon={faUserCircle} />&nbsp;{testSubmission && testSubmission.student && testSubmission.student.name}</h4></Card.Title>
                        <Card.Text style={{padding:"18.8px",marginLeft:"-10px"}}>{/* Text allignment had to be modified */}
                            <a className="text-black">Usuario: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.id}</a></a>
                            <br /><br />
                            <a className="text-black">Grado: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.grade}</a></a>
                            <br /><br />
                            <a className="text-black">Colegio: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.collegeName}</a></a>
                            <br /><br />
                            <a className="text-black">Ciudad: <a className="text-primary">{testSubmission && testSubmission.student && testSubmission.student.city}</a></a>
                            <br /><br />
                            <br />
                        </Card.Text>
                    </Card.Body>
                </Card>
                <div className="w-100 d-lg-none d-block">
                    {/* This breaks the next card to it's own row if we go below lg. */}
                </div>
                <Card className="form-group mt-4">
                    <Card.Body className="p-4">
                        <Card.Title className="text-primary text-card-header"><h4><FontAwesomeIcon icon={faBook} />&nbsp;{testSubmission && testSubmission.test && testSubmission.test.name}</h4></Card.Title>
                        <Card.Text>
                            <a className="text-black">Sesiones: <a className="text-primary">{testSubmission && testSubmission.test && testSubmission.test.numberOfSessions}</a></a>
                            <br /><br />
                            <a className="text-black">Duración: <a className="text-primary">{testSubmission && testSubmission.test && testSubmission.test.durationInMinutes} minutos</a></a>
                            <br /><br />
                            <a className="text-black">Nro Preguntas:&nbsp;
                                <a className="text-primary">{testSubmission && testSubmission.test && testSubmission.test.numberOfQuestions}</a>
                            </a>
                            <br /><br />
                            <a className="text-black">Fecha Incio: <a className="text-primary">
                                {testSubmission && testSubmission.test && testSubmission.test.dates.openAt &&
                                    <Moment tz={testSubmission.test.dates.timezone} format="DD/MM/YYYY">
                                        {testSubmission.test.dates.openAt}
                                    </Moment>
                                }
                            </a></a>
                            <br />
                            <a className="text-black">Fecha Fin: <a className="text-primary">
                                {testSubmission && testSubmission.test && testSubmission.test.dates.openAt &&
                                    <Moment tz={testSubmission.test.dates.timezone} format="DD/MM/YYYY">
                                        {testSubmission.test.dates.endAt}
                                    </Moment>
                                }
                            </a></a>
                            <br />
                            <br />
                            <span>
                                <Button className="btn btn-success" onClick={ (event)=>{ onClick(event) } } block>Iniciar Prueba&nbsp;<FontAwesomeIcon icon={faArrowCircleRight} /></Button>
                            </span>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            <Footer />
        </Container>
    );
}
