import axios from 'axios';
import store from '../store';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import isEmpty from 'lodash/isEmpty';
import msgpack from 'msgpack-lite';

import {
    TEST_SUBMISSION_DETAIL_REQUEST,
    TEST_SUBMISSION_DETAIL_FAILURE,
    TEST_SUBMISSION_DETAIL_SUCCESS
} from '../constants/actionTypes';
import {
    MCL_ABANDON_TEST_SUBMISSION_CREATE_API_ENDPOINT,
    MCL_BEGIN_TEST_SUBMISSION_CREATE_API_ENDPOINT,
    MCL_TEST_SUBMISSION_DETAIL_API_ENDPOINT,
    MCL_TEST_SUBMISSION_CREATE_API_ENDPOINT
} from '../constants/api';
import getCustomAxios from '../helpers/customAxios';


////////////////////////////////////////////////////////////////////////////////
//                               OPERATIONS                                   //
////////////////////////////////////////////////////////////////////////////////


export function postAbandonTestSubmission(data, onSuccessCallback, onFailureCallback) {
    return dispatch => {
        // Change the global state to attempting to log in.
        store.dispatch(
            setTestSubmissionDetailRequest()
        );

        const customAxios = getCustomAxios();

        // The following code will convert the `camelized` data into `snake case`
        // data so our API endpoint will be able to read it.
        let decamelizedData = decamelizeKeys(data);

        // Encode from JS Object to MessagePack (Buffer)
        var buffer = msgpack.encode(decamelizedData);

        // Perform our API submission.
        customAxios.post(MCL_ABANDON_TEST_SUBMISSION_CREATE_API_ENDPOINT.replace("<testSubmissionUuid>", data.testSubmissionUuid), buffer).then( (successResponse) => {
            // Decode our MessagePack (Buffer) into JS Object.
            const responseData = msgpack.decode(Buffer(successResponse.data));
            let testSubmission = camelizeKeys(responseData);

            // Extra.
            testSubmission['isAPIRequestRunning'] = false;
            testSubmission['errors'] = {};

            // Update the global state of the application to store our
            // user testSubmission for the application.
            store.dispatch(
                setTestSubmissionDetailSuccess(testSubmission)
            );

            // DEVELOPERS NOTE:
            // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
            // OBJECT WE GOT FROM THE API.
            if (onSuccessCallback) {
                onSuccessCallback(testSubmission);
            }

        }).catch( (exception) => {
            if (exception.response) {
                const status = exception.response.status;
                const responseBinaryData = exception.response.data; // <=--- NOTE: https://github.com/axios/axios/issues/960

                // Decode our MessagePack (Buffer) into JS Object.
                const responseData = msgpack.decode(Buffer(responseBinaryData));

                let errors = camelizeKeys(responseData);

                console.log("postAbandonTestSubmission | error:", errors); // For debuggin purposes only.

                // Send our failure to the redux.
                store.dispatch(
                    setTestSubmissionDetailFailure({
                        isAPIRequestRunning: false,
                        errors: errors
                    })
                );

                // DEVELOPERS NOTE:
                // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
                // OBJECT WE GOT FROM THE API.
                if (onFailureCallback) {
                    onFailureCallback(errors, status);
                }
            }

        }).then( () => {
            // Do nothing.
        });

    }
}


export function postBeginTestSubmission(data, onSuccessCallback, onFailureCallback) {
    return dispatch => {
        // Change the global state to attempting to log in.
        store.dispatch(
            setTestSubmissionDetailRequest()
        );

        const customAxios = getCustomAxios();

        // The following code will convert the `camelized` data into `snake case`
        // data so our API endpoint will be able to read it.
        let decamelizedData = decamelizeKeys(data);

        // Encode from JS Object to MessagePack (Buffer)
        var buffer = msgpack.encode(decamelizedData);

        // Perform our API submission.
        customAxios.post(MCL_BEGIN_TEST_SUBMISSION_CREATE_API_ENDPOINT.replace("<testSubmissionUuid>", data.testSubmissionUuid), buffer).then( (successResponse) => {
            // Decode our MessagePack (Buffer) into JS Object.
            const responseData = msgpack.decode(Buffer(successResponse.data));
            let testSubmission = camelizeKeys(responseData);

            // Extra.
            testSubmission['isAPIRequestRunning'] = false;
            testSubmission['errors'] = {};

            // Update the global state of the application to store our
            // user testSubmission for the application.
            store.dispatch(
                setTestSubmissionDetailSuccess(testSubmission)
            );

            // DEVELOPERS NOTE:
            // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
            // OBJECT WE GOT FROM THE API.
            if (onSuccessCallback) {
                onSuccessCallback(testSubmission);
            }

        }).catch( (exception) => {
            if (exception.response) {
                const status = exception.response.status;
                const responseBinaryData = exception.response.data; // <=--- NOTE: https://github.com/axios/axios/issues/960

                // Decode our MessagePack (Buffer) into JS Object.
                const responseData = msgpack.decode(Buffer(responseBinaryData));

                let errors = camelizeKeys(responseData);

                console.log("postBeginTestSubmission | error:", errors); // For debuggin purposes only.

                // Send our failure to the redux.
                store.dispatch(
                    setTestSubmissionDetailFailure({
                        isAPIRequestRunning: false,
                        errors: errors
                    })
                );

                // DEVELOPERS NOTE:
                // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
                // OBJECT WE GOT FROM THE API.
                if (onFailureCallback) {
                    onFailureCallback(errors, status);
                }
            }

        }).then( () => {
            // Do nothing.
        });

    }
}


export function postFinishTestSubmission(data, onSuccessCallback, onFailureCallback) {
    return dispatch => {
        // Change the global state to attempting to log in.
        store.dispatch(
            setTestSubmissionDetailRequest()
        );

        const customAxios = getCustomAxios();

        // The following code will convert the `camelized` data into `snake case`
        // data so our API endpoint will be able to read it.
        let decamelizedData = decamelizeKeys(data);

        // Encode from JS Object to MessagePack (Buffer)
        var buffer = msgpack.encode(decamelizedData);

        // Perform our API submission.
        customAxios.post(MCL_TEST_SUBMISSION_CREATE_API_ENDPOINT.replace("<testSubmissionUuid>", data.testSubmissionUuid), buffer).then( (successResponse) => {
            // Decode our MessagePack (Buffer) into JS Object.
            const responseData = msgpack.decode(Buffer(successResponse.data));
            let testSubmission = camelizeKeys(responseData);

            // Extra.
            testSubmission['isAPIRequestRunning'] = false;
            testSubmission['errors'] = {};

            // Update the global state of the application to store our
            // user testSubmission for the application.
            store.dispatch(
                setTestSubmissionDetailSuccess(testSubmission)
            );

            // DEVELOPERS NOTE:
            // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
            // OBJECT WE GOT FROM THE API.
            if (onSuccessCallback) {
                onSuccessCallback(testSubmission);
            }

        }).catch( (exception) => {
            if (exception.response) {
                const status = exception.response.status;
                const responseBinaryData = exception.response.data; // <=--- NOTE: https://github.com/axios/axios/issues/960

                // Decode our MessagePack (Buffer) into JS Object.
                const responseData = msgpack.decode(Buffer(responseBinaryData));

                let errors = camelizeKeys(responseData);

                console.log("postFinishTestSubmission | error:", errors); // For debuggin purposes only.

                // Send our failure to the redux.
                store.dispatch(
                    setTestSubmissionDetailFailure({
                        isAPIRequestRunning: false,
                        errors: errors
                    })
                );

                // DEVELOPERS NOTE:
                // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
                // OBJECT WE GOT FROM THE API.
                if (onFailureCallback) {
                    onFailureCallback(errors, status);
                }
            }

        }).then( () => {
            // Do nothing.
        });

    }
}




////////////////////////////////////////////////////////////////////////////////
//                                REDUX ACTIONS                               //
////////////////////////////////////////////////////////////////////////////////



export const setTestSubmissionDetailRequest = () => ({
    type: TEST_SUBMISSION_DETAIL_REQUEST,
    payload: {
        isAPIRequestRunning: true,
        errors: {}
    },
});


export const setTestSubmissionDetailSuccess = testSubmissionDetail => ({
    type: TEST_SUBMISSION_DETAIL_SUCCESS,
    payload: testSubmissionDetail,
});


export const setTestSubmissionDetailFailure = testSubmissionDetail => ({
    type: TEST_SUBMISSION_DETAIL_FAILURE,
    payload: testSubmissionDetail,
});



////////////////////////////////////////////////////////////////////////////////
//                                 UTILITY                                    //
////////////////////////////////////////////////////////////////////////////////

/**
 * Utility function takes the API data and converts it to HTML dropdown
 * options which will be consumed by the `react-select` library elements.
 */
export function getTestSubmissionReactSelectOptions(testSubmissionList=[], selectName="testSubmission") {
    const testSubmissionOptions = [];
    const isNotProductionsEmpty = isEmpty(testSubmissionList) === false;
    if (isNotProductionsEmpty) {
        const results = testSubmissionList.results;
        const isResultsNotEmpty = isEmpty(results) === false;
        if (isResultsNotEmpty) {
            for (let i = 0; i < results.length; i++) {
                let testSubmission = results[i];
                testSubmissionOptions.push({
                    selectName: selectName,
                    value: testSubmission.slug,
                    label: testSubmission.fullName
                });
                // console.log(testSubmission);
            }
        }
    }
    return testSubmissionOptions;
}
