import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import { Offline, Online } from "react-detect-offline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faExclamationCircle, faCheckCircle, faLock } from '@fortawesome/free-solid-svg-icons';

import Footer from '../../common/footerComponent';
import TopNavigationWithCountdownTimerComponent from "../../navigation/topNavigationWithCountdownTimerComponent";
import ContextIteratorComponent from "./contextComponent";
import StatementIteratorComponent from "./statementComponent";
import OptionIteratorComponent from "./optionComponent";
import AbandonModalComponent from "./abandonModelComponent";
import InfoModalComponent from "./infoModelComponent";
import { TEST_SUBMISSION_IN_PROGRESS_STATE } from "../../../constants/states";


export default function TestSubmissionAnswerComponent(props) {
    const {
        onSubmitClick, onChoiceClick, testSubmissionObj, questionObj, testSessionObj,
        answerChoiceSlug, errors, startCountdownTimerAtTimestamp, isLoading,
        onCountdownTimerCompletion, onCountdownTimerTick, onAbandonTestSubmissionClick,
        showAbandonModal, onAbandonCloseClick, onAbandonOKClick,
        showInfoModal, onInfoCloseClick, onInfoOKClick
    } = props;

    // console.log(testSubmissionObj);
    return(
        <Fragment>
            <TopNavigationWithCountdownTimerComponent
                title={testSubmissionObj && testSubmissionObj.test && testSubmissionObj.test.name}
                studentName={testSubmissionObj && testSubmissionObj.student && testSubmissionObj.student.name}
                sessionName={testSessionObj && testSessionObj.name}
                questionCurrentPosition={questionObj && questionObj.order}
                questionsCount={testSessionObj && testSessionObj.numberOfQuestions}
                startCountdownTimerAtTimestamp={startCountdownTimerAtTimestamp}
                timezone={testSubmissionObj && testSubmissionObj.student && testSubmissionObj.student.timezone}
                onCountdownTimerCompletion = {onCountdownTimerCompletion && onCountdownTimerCompletion}
                onCountdownTimerTick = {onCountdownTimerTick && onCountdownTimerTick}
            />
            <Container className="question-container">
                <div>
                    <Row className="mt-5">
                        <Col className="mt-5">
                            <br />
                        </Col>
                    </Row>

                    <Offline>
                        <Row>
                            <Col>
                                <Alert key={"offline-banner"} variant="warning">
                                    {/* <strong>Offline Mode:</strong>&nbsp;No internet detected. You won't be able to submit your test until you regain an internet connection. */ }
                                    <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                                </Alert>
                            </Col>
                        </Row>
                    </Offline>

                    {isEmpty(errors) === false &&
                        <Row>
                            <Col>
                                <Alert key="danger-alert" variant="danger">
                                    <FontAwesomeIcon icon={faExclamationCircle} />&nbsp;Por favor selecciona una opción antes de continuar.
                                </Alert>
                            </Col>
                        </Row>
                    }

                    {questionObj &&
                        <Fragment>
                            <Row key={questionObj.slug}>
                                <Col>
                                    <CardDeck>
                                        <Card style={{backgroundColor:"#EDEFEE"}} className="question-card">
                                            <Card.Body>
                                                <AbandonModalComponent
                                                    showAbandonModal={showAbandonModal}
                                                    onAbandonCloseClick={onAbandonCloseClick}
                                                    onAbandonOKClick={onAbandonOKClick}
                                                />
                                                <InfoModalComponent
                                                    showInfoModal={showInfoModal}
                                                    onInfoCloseClick={onInfoCloseClick}
                                                    onInfoOKClick={onInfoOKClick}
                                                />
                                                <Accordion>
                                                    <Card style={{backgroundColor:"#EDEFEE",border:"none"}}>
                                                        <Row noGutters>
                                                            <Col>
                                                                {/*
                                                                    "Show Context" button if this question has context that we need to worry about.
                                                                */}
                                                                {questionObj.context !==null &&
                                                                    <Accordion.Toggle as={Button} eventKey="0" className="btn btn-primary col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-6" style={{padding:"10px",}}>
                                                                    Ver Contexto
                                                                    </Accordion.Toggle>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    className="btn btn-success float-right col-xs-12 col-sm-12 col-md-12 col-lg-6"
                                                                    size="md"
                                                                    type="button"
                                                                    style={{padding:"10px",}}
                                                                    onClick={(event,id)=>{ onSubmitClick(event) }}
                                                                    disabled={isLoading}>
                                                                    {questionObj.isLastQuestionInSession === true
                                                                        ? <span><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Finalizar</span>
                                                                        : <span>Siguiente&nbsp;<FontAwesomeIcon icon={faArrowCircleRight} /></span>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Accordion.Collapse eventKey="0" style={{backgroundColor:"#EDEFEE",border:"0.5px solid grey",marginTop:"17px"}}>
                                                                <Card body>
                                                                    <ContextIteratorComponent
                                                                        questionObj={questionObj}
                                                                    />
                                                                </Card>
                                                            </Accordion.Collapse>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="collapContent">
                                                                    <h5 className="text-dark mt-3">Pregunta {questionObj.order}{"."}</h5>
                                                                    <div className="text-justify">
                                                                        {questionObj.statements &&
                                                                            <StatementIteratorComponent
                                                                                order={questionObj.order}
                                                                                statements={questionObj.statements}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="collapContent">
                                                                    <div>
                                                                        {isEmpty(questionObj) === false &&
                                                                            <OptionIteratorComponent key={`options-with-chosen-slug-${answerChoiceSlug}`}
                                                                                options={questionObj.options}
                                                                                answerChoiceSlug={answerChoiceSlug}
                                                                                onChoiceClick={onChoiceClick}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col></Col>
                                                            <Col>
                                                                <Button
                                                                    className="btn btn-success float-right col-xs-12 col-sm-12 col-md-12 col-lg-6"
                                                                    size="md"
                                                                    type="button"
                                                                    style={{padding:"10px",}}
                                                                    onClick={(event,id)=>{ onSubmitClick(event) }}
                                                                    disabled={isLoading}>
                                                                    {questionObj.isLastQuestionInSession === true
                                                                        ? <span><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Finalizar</span>
                                                                        : <span>Siguiente&nbsp;<FontAwesomeIcon icon={faArrowCircleRight} /></span>
                                                                    }
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Accordion>
                                            </Card.Body>
                                        </Card>
                                    </CardDeck>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Offline>
                                        <FontAwesomeIcon icon={faLock} />&nbsp;Abandonar Prueba
                                    </Offline>
                                    <Online>
                                        <Button variant="link" onClick={(event)=>{onAbandonTestSubmissionClick(event)}}>
                                            Abandonar Prueba
                                        </Button>
                                    </Online>
                                </Col>
                                {/*
                                <Col className="text-right">
                                    <Button variant="link" onClick={(event)=>{onInfoTestSubmissionClick(event)}}>
                                        Instantánea Prueba
                                    </Button>
                                </Col>
                                */}
                            </Row>
                        </Fragment>
                    }
                </div>
                <Footer />
            </Container>
        </Fragment>
    );
}
