import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import '../../App.css';
import error404 from '../../img/error404.jpeg';


export default function NotFound404Component(props){

    return(
      <Container fluid>
          <span className="footer-copyright text-center py-3" style={{bottom:"0"}}>
              <Image src={error404} width="1295vh" responsive />
          </span>
      </Container>

        );
}
