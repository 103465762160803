import isEmpty from 'lodash/isEmpty';
import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Countdown from 'react-countdown';
import Alert from 'react-bootstrap/Alert';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { Offline, Online } from "react-detect-offline";

import TopNavigationWithCountdownTimerComponent from "../navigation/topNavigationWithCountdownTimerComponent";
import footer from '../../img/footer.png';


export default function TestSubmissionAnswerComponent(props) {
    const {
        testBreakObj, startCountdownTimerAtTimestamp, isLoading, testSubmissionObj,
        errors, onCountdownTimerCompletion, onCountdownTimerTick, onClick
    } = props;

    return(
        <Container>
            <TopNavigationWithCountdownTimerComponent
                title={testSubmissionObj && testSubmissionObj.test && testSubmissionObj.test.name}
                studentName={testSubmissionObj && testSubmissionObj.student && testSubmissionObj.student.name}
                sessionName={"Descanso!"}
                questionCurrentPosition={1}
                questionsCount={1}
                startCountdownTimerAtTimestamp={startCountdownTimerAtTimestamp}
                timezone={testSubmissionObj && testSubmissionObj.student && testSubmissionObj.student.timezone}
                onCountdownTimerCompletion = {onCountdownTimerCompletion && onCountdownTimerCompletion}
                onCountdownTimerTick = {onCountdownTimerTick && onCountdownTimerTick}
            />
            <Offline>
                <Row>
                    <Col>
                        <Alert key={"offline-banner"} variant="warning">
                            {/* <strong>Offline Mode:</strong>&nbsp;No internet detected. You won't be able to submit your test until you regain an internet connection. */ }
                            <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                        </Alert>
                    </Col>
                </Row>
            </Offline>
            <Row className="mt-5">
                <Col className="mt-5">
                    <Jumbotron className="mt-5">
                        <h1>Descanso!</h1>
                        <p style={{fontSize:"25px"}}>
                          {/* You are now on break, please come back when the countdown timer has finished so you may being your next test. */}
                          ¡Ahora estás en receso! Vuelve cuando se haya cumplido el tiempo para continuar con la siguiente sesión.

                        </p>
                        <p>
                            <Button variant="primary" onClick={(event)=>{onClick()}}>Continuar</Button>
                        </p>
                    </Jumbotron>
                </Col>
            </Row>
            <Row>
                <span className="footer-copyright text-center py-3" style={{bottom:"0"}}>
                    <img src={footer} width="300vh" style={{ marginLeft: '420px' }}  />
                </span>
            </Row>
          {/*



          */}
      </Container>

    );
}
