import React from 'react';
import { BrowserRouter as Router, Route, withRouter, Switch } from "react-router-dom";

import withTestInitializer from "../hoc/withTestInitializer";
import withRedirector from "../hoc/withRedirector";
import NotFound404Container from './general/notFound404Container';
import IndexContainer from './general/indexContainer';
import LaunchpadContainer from './launchpad/launchpadContainer';
import TestSubmissionAgreementContainer from './testSubmission/agreementContainer';
import TestSubmissionStartContainer from './testSubmission/startContainer';
import TestSubmissionAnswerContainer from "./testSubmission/answerContainer";
import TestSubmissionBreakContainer from "./testSubmission/breakContainer";
import TestSubmissionCompleteSendingContainer from "./testSubmission/completeSendingContainer";
import TestSubmissionCompleteContainer from "./testSubmission/completeContainer";
import PDFViewContainer from "./testSubmission/pdfViewContainer";
import BadRequest400Container from "./general/badRequest400Container";
import ServerError500Container from "./general/serverError500Container";
import ErrorSixContainer from "./general/customErrors/errorSixContainer";
import ErrorEightContainer from "./general/customErrors/errorEightContainer";
import ErrorNineContainer from "./general/customErrors/errorNineContainer";
import ErrorTenContainer from "./general/customErrors/errorTenContainer";
import ErrorElevenContainer from "./general/customErrors/errorElevenContainer";
import ErrorTwelveContainer from "./general/customErrors/errorTwelveContainer";
import ErrorThirteenContainer from "./general/customErrors/errorThirteenContainer";
import Error01Container from "./general/customErrors/error01Container";
import Error02Container from "./general/customErrors/error02Container";
import Error03Container from "./general/customErrors/error03Container";
import Error04Container from "./general/customErrors/error04Container";
import Error14Container from "./general/customErrors/error14Container";
import '../App.css';


class AppContainer extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={IndexContainer} />
                    <Route path="/launchpad" exact component={LaunchpadContainer} />
                    <Route path="/submission/start" exact component={
                        withRedirector(
                            withTestInitializer(TestSubmissionStartContainer)
                        )
                    } />
                    <Route path="/submission/agreement" exact component={
                        withRedirector(TestSubmissionAgreementContainer)
                    } />
                    <Route path="/submission/question" exact component={
                        withRedirector(TestSubmissionAnswerContainer)
                    } />
                    <Route path="/submission/break" exact component={
                        withRedirector(TestSubmissionBreakContainer)
                    } />
                    <Route path="/submission/sending" exact component={TestSubmissionCompleteSendingContainer} />
                    <Route path="/submission/completed" exact component={
                        withRedirector(TestSubmissionCompleteContainer)
                    } />
                    <Route path="/submission/completed/pdf" exact component={PDFViewContainer} />
                    <Route path="/400" exact component={BadRequest400Container} />
                    <Route path="/400-more-then-one-in-progress-submission-open" exact component={ErrorSixContainer} />
                    <Route path="/400-abondoned" exact component={ErrorEightContainer} />
                    <Route path="/400-incomplete" exact component={ErrorNineContainer} />
                    <Route path="/400-complete" exact component={ErrorTenContainer} />
                    <Route path="/400-old-cache" exact component={ErrorElevenContainer} />
                    <Route path="/400-prerequisite-not-met" exact component={ErrorTwelveContainer} />
                    <Route path="/400-test-in-progress" exact component={ErrorThirteenContainer} />
                    <Route path="/400-empty-payload" exact component={Error01Container} />
                    <Route path="/400-bad-base64-encoding" exact component={Error02Container} />
                    <Route path="/400-bad-payload-content" exact component={Error03Container} />
                    <Route path="/400-payload-missing-field" exact component={Error04Container} />
                    <Route path="/400-more-then-one-pending-submission-open" exact component={Error14Container} />
                    <Route path="/500" exact component={ServerError500Container} />
                    <Route component={NotFound404Container} />
                </Switch>
            </Router>
        );
    }
}

export default withRouter(AppContainer);
