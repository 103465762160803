import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';

import {
    HTML_ELEMENT_TYPE,
    TEXT_ELEMENT_TYPE,
    IMAGE_ELEMENT_TYPE
} from "../../../constants/states";


export default function ContextIteratorComponent({ questionObj }) {
    const { context } = questionObj;
    if (isEmpty(context)) {
        return null;
    }
    return (
        <Fragment>
            <h5 className="text-dark">Contexto</h5>
            <div className="container text-justify" key={context.slug}>
                {context.questionContexts.map(
                    (questionContext) =>
                    <QuestionContextComponent
                        key={`${questionContext.slug}-qc`}
                        questionContext={questionContext}
                    />
                )}
            </div>
        </Fragment>
    );
}


function QuestionContextComponent({questionContext}) {
    return (
        <div>
            {questionContext.type === TEXT_ELEMENT_TYPE &&
                <div>
                    {questionContext.content}
                </div>
            }
            {questionContext.type === IMAGE_ELEMENT_TYPE &&
                <div>
                    <img src={questionContext.content} />
                </div>
            }
            {questionContext.type === HTML_ELEMENT_TYPE &&
              <div dangerouslySetInnerHTML={{__html: questionContext.content}} />
            }
        </div>
    );
}
