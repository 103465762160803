import React, { Component } from "react";

import BadRequest400Component from "../../components/general/badRequest400Component";


export default class BadRequest400Container extends Component {
    constructor(props) {
        super(props);

        // Extract the `query parameter` from the URL.
        // Special thanks: https://stackoverflow.com/a/43220620
        const params = new URLSearchParams(props.location.search);
        const errorId = params.get('errorId');
        const errorMessage = params.get('errorMessage');

        this.state = {
            errorId: errorId,
            errorMessage: errorMessage,

        };
    }

    render() {
        const { errorId, errorMessage } = this.state;
        return (
            <BadRequest400Component
                errorId={errorId}
                errorMessage={errorMessage}
            />
        );
    }
}
