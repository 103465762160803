import React, { Component } from "react";
import { connect } from 'react-redux';
import isEmpty from "lodash/isEmpty";

import LaunchpadComponent from "../../components/launchpad/launchpadComponent";
import { postPasswordlessLoginFromLaunchpad } from "../../actions/loginAction";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import { TEST_SUBMISSION_COMPLETED_STATE } from "../../constants/states";
import { MARKINGCLOUD_FRONTEND_VERSION } from "../../constants/version";
import { localStorageGetIntegerItem } from "../../helpers/localStorageUtility";
import { setAccessTokenInLocalStorage } from '../../helpers/tokenUtility';
import getBrowserMetrics from '../../helpers/browserMetricsHelper';


class LaunchpadContainer extends Component {

    /**
     *  Initializer
     *------------------------------------------------------------
     */

    constructor(props) {
        super(props);

        // // IMPORTANT:
        // // We need to completely clear the `localStorage` when this component
        // // starts up because the `LaunchpadContainer` is responsible for
        // // working from a clean state.
        // localStorage.clear();

        // For diagnostic purposes, attach some browser details to help the
        // programmers diagnose problems pertaining to test submissions.
        const browserMetrics = getBrowserMetrics();

        // Extract the `query parameter` from the URL.
        // Special thanks: https://stackoverflow.com/a/43220620
        const params = new URLSearchParams(props.location.search);
        const payload = params.get('payload');

        this.state = {
            payload: payload,
            browserMetrics: browserMetrics,
        };
        this.onSuccessCallback = this.onSuccessCallback.bind(this);
        this.onFailureCallback = this.onFailureCallback.bind(this);
    }

    /**
     *  Utility
     *------------------------------------------------------------
     */

    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        const { payload } = this.state;

        // If no payload placed then we redirect back to the homepage.
        if (payload === undefined || payload === null || payload === "") {
            this.props.history.push("/");
        }

        // Else we will communicate with the API web-server.
        this.props.postPasswordlessLoginFromLaunchpad(
            {
                payload: this.state.payload,
                browserMetrics: this.state.browserMetrics,
                frontendVersion: MARKINGCLOUD_FRONTEND_VERSION,
            },
            this.onSuccessCallback,
            this.onFailureCallback
        );
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    onSuccessCallback(testSubmission) {
        console.log("LaunchpadContainer | onSuccessCallback | Response:", testSubmission, "\n\n");

        /**
         *  The following code will load up the `evaluator` which is responsible
         *  for maintaining the model of our test submission.
         */
        const evaluatorStore = getEvaluatorStoreInstance();
        console.log("LaunchpadContainer | onSuccessCallback | evaluatorStore:\n",evaluatorStore,"\n\n");

        // If the test submission has already been completed then redirect the
        // user to the last page, do not continue with this function.
        if (testSubmission.testSubmissionStatus === TEST_SUBMISSION_COMPLETED_STATE) {
            if (evaluatorStore.isRunningTestSubmission(testSubmission)) {
                console.log("LaunchpadContainer | onSuccessCallback | Info: Skipping and redirecting.\n");
                this.props.history.push("/submission/completed");
                return;
            }
            console.log("LaunchpadContainer | onSuccessCallback | Info: Test already completed\n");
            console.log("LaunchpadContainer | onSuccessCallback | Action: Clear cache\n\n");
            localStorage.clear();
            console.log("LaunchpadContainer | onSuccessCallback | Action: Update evaluator store\n\n");
            const newEvaluatorStore = getEvaluatorStoreInstance();
            newEvaluatorStore.setTestSubmission(testSubmission);
            newEvaluatorStore.overrideStatus(TEST_SUBMISSION_COMPLETED_STATE);
            this.props.history.push("/submission/completed");
            return;
        }
        console.log("LaunchpadContainer | onSuccessCallback | Info: New test\n");

        // Check to see if our evaluator is currently set for the test submission.
        if (evaluatorStore.isRunningTestSubmission(testSubmission) === false) {
            console.log("LaunchpadContainer | onSuccessCallback | Info: New Test Submission\n\n");
            console.log("LaunchpadContainer | onSuccessCallback | Action: Clear cache\n\n");
            localStorage.clear();

            // SAVE OUR CREDENTIALS IN PERSISTENT STORAGE. THIS IS AN IMPORTANT
            // STEP BECAUSE OUR TOKEN UTILITY HELPER NEEDS THIS.
            console.log("LaunchpadContainer | onSuccessCallback | Action: Save token\n\n");
            setAccessTokenInLocalStorage(testSubmission.student.accessToken);

            console.log("LaunchpadContainer | onSuccessCallback | Action: Setting test submission\n\n");
            const newEvaluatorStore = getEvaluatorStoreInstance();
            newEvaluatorStore.setTestSubmission(testSubmission);
        }
        this.props.history.push("/submission/start"); // Redirect to our start page.
    }

    onFailureCallback(errors, status) {
        // For debugging purposes only.
        console.log("LaunchpadContainer | Log\n");
        console.log("onFailureCallback |", errors, "|", status,"\n\n");
        if (status === 400) {
            try {
                const { errorId, errorMessage } = errors.payload;
                if (errorId === undefined) {
                    const errorMessage = "The payload is missing in the URL";
                    this.props.history.push("/400?errorId=0&errorMessage="+errorMessage);
                    return;
                } else if (errorId === 1 || errorId === "1") {
                    this.props.history.push("/400-empty-payload");
                    return;
                } else if (errorId === 2 || errorId === "2") {
                    this.props.history.push("/400-bad-base64-encoding");
                    return;
                } else if (errorId === 3 || errorId === "3") {
                    this.props.history.push("/400-bad-payload-content");
                    return;
                } else if (errorId === 4 || errorId === "4") {
                    this.props.history.push("/400-payload-missing-field");
                    return;
                } else if (errorId === 6 || errorId === "6") {
                    const { errorTestName } = errors.payload;
                    localStorage.setItem("ErrorTestName", errorTestName);
                    this.props.history.push("/400-more-then-one-in-progress-submission-open");
                    return;
                } else if (errorId === 8 || errorId === "8") {
                    this.props.history.push("/400-abondoned");
                    return;
                } else if (errorId === 9 || errorId === "9") {
                    this.props.history.push("/400-incomplete");
                    return;
                } else if (errorId === 10 || errorId === "10") {
                    this.props.history.push("/400-complete");
                    return;
                } else if (errorId === 11 || errorId === "11") {
                    /**
                     *  The following block of code will be attempting to clear the
                     *  cache automatically before asking the user to clear the
                     *  cache manually. The algorithm is (1) Make first attempt
                     *  at reloading the page. (2) Make second attempt at reloading
                     *  the page.
                     *
                     *  Special Thanks:
                     *  https://stackoverflow.com/questions/5721704/window-location-reload-with-clear-cache
                     */
                    const redirectCount = localStorageGetIntegerItem("MARKINGCLOUD_LOGIN_FROM_LAUNCHPAD_REDIRECT_COUNT");
                    if (redirectCount === 0 || isNaN(redirectCount)) {
                        localStorage.clear();
                        localStorage.setItem("MARKINGCLOUD_LOGIN_FROM_LAUNCHPAD_REDIRECT_COUNT", 1)
                        window.location.href = window.location.href
                    } else if (redirectCount === 1) {
                        localStorage.setItem("MARKINGCLOUD_LOGIN_FROM_LAUNCHPAD_REDIRECT_COUNT", 2)
                        window.location.reload(true);
                    } else if (redirectCount === 2) {
                        localStorage.removeItem("MARKINGCLOUD_LOGIN_FROM_LAUNCHPAD_REDIRECT_COUNT")
                        this.props.history.push("/400-old-cache");
                    }
                    return;
                } else if (errorId === 12 || errorId === "12") {
                    const { errorTestName } = errors.payload;
                    localStorage.setItem("ErrorTestName", errorTestName);
                    this.props.history.push("/400-prerequisite-not-met");
                    return;
                } else if (errorId === 14 || errorId === "14") {
                    const { errorTestName } = errors.payload;
                    localStorage.setItem("ErrorTestName", errorTestName);
                    this.props.history.push("/400-more-then-one-pending-submission-open");
                    return;
                }
                this.props.history.push("/400?errorId=0&errorMessage="+errorMessage);
                return;
            } catch (e) {
                // const errorMessage = errors;
            }
        }
    }

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        return (
            <LaunchpadComponent
            />
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testSubmission: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        postPasswordlessLoginFromLaunchpad: (payload, successCallback, failedCallback) => {
            dispatch(postPasswordlessLoginFromLaunchpad(payload, successCallback, failedCallback))
        },
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LaunchpadContainer);
