import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';

import {
    TEST_SUBMISSION_PENDING_STATE,
    TEST_SUBMISSION_IN_PROGRESS_STATE,
    TEST_SUBMISSION_BREAK_STATE,
    TEST_SUBMISSION_COMPLETED_STATE
} from "../constants/states";
import {
    localStorageGetIntegerItem,
    localStorageGetObjectItem,
    localStorageSetObjectOrArrayItem
} from "../helpers/localStorageUtility";
import { getAwareNowDateTimeString } from "../helpers/momentHelper";
import { forceUnicodeEncoding } from "../helpers/utf8Helper";

const SESSION_MODE = "sessionMode";
const BREAK_MODE = "breakMode";
const DONE_MODE = "doneMode";


/**
 *  Function will return our singleton `EvaluatorStore` object.
 */
export default function getEvaluatorStoreInstance() {
    const dehydratedData = localStorageGetObjectItem("MCL_EVALUATOR_STORE")
    if (dehydratedData === undefined) {
        return new EvaluatorStore();
    } else {
        return new EvaluatorStore(dehydratedData);
    }
}

class EvaluatorStore {

    /**
     *  Constructor will create our object by taking the `dehydratedData`
     *  and hydrating it so our object can be populated with data.
     */
    constructor(dehydratedData){
        if (dehydratedData) {
            if (dehydratedData.hasOwnProperty("_testSubmission")) {
                this._testSubmission = dehydratedData._testSubmission;
            }
            if (dehydratedData.hasOwnProperty("_test")) {
                this._test = dehydratedData._test;
            }
            if (dehydratedData.hasOwnProperty("_sessionIndex")) {
                // The session index property is responsible for storing the
                // index position value of our current session from the
                // 'test/sessions' array.
                this._sessionIndex = dehydratedData._sessionIndex;
            }
            if (dehydratedData.hasOwnProperty("_breakIndex")) {
                // The break property is responsible for storing the index
                // pointing to the break we currently are in from the
                // "test/breaks" array.
                this._breakIndex = dehydratedData._breakIndex;
            }
            if (dehydratedData.hasOwnProperty("_question")) {
                // The question property is the question we are currently in.
                this._question = dehydratedData._question;
            }
            if (dehydratedData.hasOwnProperty("_status")) {
                // The status is used to the control the 'state' of our test
                // evaluation that we are in.
                this._status = dehydratedData._status;
            } else {
                this._status = TEST_SUBMISSION_PENDING_STATE;
            }
            if (dehydratedData.hasOwnProperty("_mode")) {
                // Property controls if we are running an evaluation or are
                // we breaking for a pause.
                this._mode = dehydratedData._mode;
            }
            if (dehydratedData.hasOwnProperty("_answers")) {
                // Property stores the answers the user selected. Later on we
                // will iterate through these answers and provide a
                // post-processed answers to our API.
                this._answers = dehydratedData._answers;
            }
            if (dehydratedData.hasOwnProperty("_startAtTimestamp")) {
                // Property stores the integer epoch time value of when this
                // test evaluation started.
                this._startAtTimestamp = dehydratedData._startAtTimestamp;
            }
            if (dehydratedData.hasOwnProperty("_startAt")) {
                // Property stores the string value of when test started.
                this._startAt = dehydratedData._startAt;
            }
            if (dehydratedData.hasOwnProperty("_endAtTimestamp")) {
                this._endAtTimestamp = dehydratedData._endAtTimestamp;
            }
            if (dehydratedData.hasOwnProperty("_endAt")) {
                this._endAt = dehydratedData._endAt;
            }
            if (dehydratedData.hasOwnProperty("_startCountdownTimerAtTimestamp")) {
                // The property value is used to keep track of when the
                // countdown timer started. This value is a integer epoc time
                // value. This value can be for either the current session or
                // the current break.
                this._startCountdownTimerAtTimestamp = dehydratedData._startCountdownTimerAtTimestamp;
            }
            if (dehydratedData.hasOwnProperty("_wasSubmittedToAPI")) {
                // Property controls whether we submitted our test results to
                // the remote API web-server.
                this._wasSubmittedToAPI = dehydratedData._wasSubmittedToAPI;
            }
            if (dehydratedData.hasOwnProperty("_pickedStartAt")) {
                this._pickedStartAt = dehydratedData._pickedStartAt;
            }
            if (dehydratedData.hasOwnProperty("_pickedSlug")) {
                this._pickedSlug = dehydratedData._pickedSlug;
            }
            if (dehydratedData.hasOwnProperty("_pickedLetter")) {
                this._pickedLetter = dehydratedData._pickedLetter;
            }
            if (dehydratedData.hasOwnProperty("_pickedPsuedoLetter")) {
                this._pickedPsuedoLetter = dehydratedData._pickedPsuedoLetter;
            }
            if (dehydratedData.hasOwnProperty("_pickedSubjectName")) {
                this._pickedSubjectName = dehydratedData._pickedSubjectName;
            }
            if (dehydratedData.hasOwnProperty("_pickedOrderNum")) {
                this._pickedOrderNum = dehydratedData._pickedOrderNum;
            }
            if (dehydratedData.hasOwnProperty("_uploadContent")) {
                // Property used to store the data content that was sent to
                // the remote API web-server.
                this._uploadContent = dehydratedData._uploadContent;
            }
            if (dehydratedData.hasOwnProperty("_isLate")) {
                this._isLate = dehydratedData._isLate;
            }
        } else {
            this._status = TEST_SUBMISSION_PENDING_STATE;
        }
    }

    /**
     *  Utlity function used to save our object's properties to persistent
     *  storage in our domain.
     */
    _saveDehydrateStore() {
        localStorageSetObjectOrArrayItem("MCL_EVALUATOR_STORE", this);
    }

    /**
     *  Set the `TestSubmission` we will be evalating. By setting a new test
     *  submission, we are completely clearing the evaluator.
     */
    setTestSubmission(testSubmission) {
        this._testSubmission = testSubmission;
        delete this._test;
        delete this._sessionIndex;
        delete this._breakIndex;
        delete this._question;
        delete this._status;
        delete this._mode;
        delete this._answers;
        delete this._startAtTimestamp;
        delete this._startAt;
        delete this._endAtTimestamp;
        delete this._endAt;
        delete this._startCountdownTimerAtTimestamp;
        delete this._wasSubmittedToAPI;
        delete this._pickedStartAt;
        delete this._pickedSlug;
        delete this._pickedLetter;
        delete this._pickedPsuedoLetter;
        delete this._pickedSubjectName;
        delete this._pickedOrderNum;
        delete this._uploadContent;
        delete this._isLate;
        this._saveDehydrateStore();
    }

    /**
     *  Set the `Test` we will be evalating.
     */
    setTest(test) {
        this._test = test;
        this._saveDehydrateStore();
    }

    /**
     *  Function used to override status.
     */
    overrideStatus(status) {
        this._status = status;
        this._saveDehydrateStore();
    }

    /**
     *  Return `true` or `false` depending on if we are running the inputted
     *  `TestSubmission` for this evaluator.
     */
    isRunningTestSubmission(testSubmission) {
        if (this.hasOwnProperty("_testSubmission")) {
            if (isEmpty(this._testSubmission)) {
                return false;
            }
            return this._testSubmission.testSubmissionUuid === testSubmission.testSubmissionUuid;
        }
        return false;
    }

    getTestSubmissionUuid() {
        if (this.hasOwnProperty("_testSubmission")) {
            if (isEmpty(this._testSubmission)) {
                return null;
            }
            return this._testSubmission.testSubmissionUuid
        }
        return null;
    }

    getAccessToken() {
        return this._testSubmission.student.accessToken;
    }

    getTest() {
        if (this.hasOwnProperty("_test")) {
            return this._test;
        }
        return null;
    }

    /**
     *  Returns `true` or `false` depending on if our evaluation is ready to begin.
     */
    isReadyToBegin() {
        if (this.hasOwnProperty("_testSubmission")) {
            if (this.hasOwnProperty("_test")) {
                let isReady = this._testSubmission !== undefined && this._testSubmission !== null && this._testSubmission !== "";
                isReady &= this._test !== undefined && this._test !== null && this._test !== "";
                return isReady;
            }
        }
        return false;
    }

    /**
     *  Function starts our evalation to begin.
     */
    begin() {
        if (isEmpty(this._test)) { // Defensive code.
            alert("Please set the `test` before beginning.");
            return;
        }
        if (isEmpty(this._testSubmission)) { // Defensive code.
            alert("Please set the `testSubmission` before beginning.");
            return;
        }

        const firstSessionObj = first(this._test.sessions);
        const firstQuestionObj = first(firstSessionObj.questions);

        const duration = parseInt(firstSessionObj.durationInMinutes) * 60 * 1000
        this._status = TEST_SUBMISSION_IN_PROGRESS_STATE
        this._sessionIndex = 0; // Array starts at zero.
        this._breakIndex = 0;
        this._mode = SESSION_MODE; // Always start with a session!
        this._question = firstQuestionObj;
        this._answers = {}; // "slug-xyz": {"startAt": "xyz", "endAt": "xyz", "letter": "A",}
        this._startAtTimestamp = Date.now();
        this._startAt = getAwareNowDateTimeString(this._test.timezone);
        this._startCountdownTimerAtTimestamp = Date.now() + duration;
        this._wasSubmittedToAPI = false;
        this._pickedStartAt = getAwareNowDateTimeString(this._test.timezone);
        this._pickedSlug = "";
        this._pickedLetter = "";
        this._pickedPsuedoLetter = "";
        this._pickedSubjectName = "";
        this._pickedOrderNum = "";
        this._uploadContent = "";
        this._saveDehydrateStore(); // Save to persistent storage.
    }

    /**
     *  Return total duration of the countdown timer.
     */
    getStartCountdownTimerAtTimestamp() {
        return this._startCountdownTimerAtTimestamp;
    }

    /**
     *  Returns how much duration we have left from the current time and the
     *  countdown timer which started. Please note a negative value means the
     *  time officially finished.
     */
    getRemainingTimeInMinutes() {
        try {
            // https://www.tutorialspoint.com/How-to-get-time-difference-between-two-timestamps-in-seconds
            const thenTimestamp = parseInt(this._startCountdownTimerAtTimestamp);
            const diffTime = (thenTimestamp - Date.now()) / 1000;
            const durationInMinutes = (diffTime / 60) % 60;
            return Math.floor(durationInMinutes);
        } catch(e) {
            console.error("getRemainingTimeInMinutes | Exception:", e);
            return 0
        }
    }

    /**
     *  Returns how much time elapsed since the test began.
     */
    getElapsedTimeInMinutes() {
        try {
            // https://www.tutorialspoint.com/How-to-get-time-difference-between-two-timestamps-in-seconds
            const thenTimestamp = parseInt(this._startAtTimestamp);
            const nowTimestamp = parseInt(this._endAtTimestamp); // Date.now();
            const diffTimeInSeconds = Math.abs(nowTimestamp - thenTimestamp) / 1000;

            // https://stackoverflow.com/a/1322798
            let totalSeconds = diffTimeInSeconds;
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600; // NOTE: This means we are normalizing for 1 hour, meaning our computations are based on the hour.
            let minutes = Math.floor(totalSeconds / 60);
            let seconds = Math.floor(totalSeconds % 60);

            // console.log("hours: " + hours);
            // console.log("minutes: " + minutes);
            // console.log("seconds: " + seconds);

            // If you want strings with leading zeroes:
            minutes = String(minutes).padStart(2, "0");
            hours = String(hours).padStart(2, "0");
            seconds = String(seconds).padStart(2, "0");
            // console.log(hours + ":" + minutes + ":" + seconds);

            return hours + ":" + minutes + ":" + seconds;
        } catch(e) {
            console.error("getElapsedTimeInMinutes | Exception:", e);
            return 0
        }
    }

    /**
     *  Return details of the question we are currently on in the evaluation.
     */
    getCurrentQuestion() {
        return this._question;
    }

    /**
     *  Return details of the session we are currently on in the evaluation.
     */
    getCurrentTestSession() {
        return this._test.sessions[this._sessionIndex];
    }

    /**
     *  Return details of the break we are currently on in the evaluation.
     */
    getCurrentBreak() {
        return this._test.breaks[this._breakIndex];
    }

    /**
     *  Return `true` or `false` depending on whether we are on the last
     *  question in the test or not.
     */
    isLastQuestionInTest() {
        if (this.hasOwnProperty("_question")) {
            return this._question.isLastQuestionInTest;
        }
        return null;
    }

    /**
     *  Return `true` or `false` depending on whether we are on the last
     *  question in the session or not.
     */
    isLastQuestionInSession() {
        if (this.hasOwnProperty("_question")) {
            return this._question.isLastQuestionInSession;
        }
        return null;
    }

    /**
     *  Returns the status of this evalator.
     */
    getStatus() {
        if (this.hasOwnProperty("_status")) {
            return this._status;
        }
        return null;
    }

    /**
     *  Function checks if the session or break ran out of time and then
     *  do the following:
     *
     *  (1) Change state to either (a) on break, (b) in progress or (c) complete.
     *  (2) Reset countdown timer based on the duration of next event.
     *  (3) Load up either the next session / break.
     *
     *  This function needs to be integrated with somesort of timer loop to
     *  run continously in the background.
     */
    tickOnComplete() {
        const testSessionObj = this.getCurrentTestSession();
        const testBreakObj = this.getCurrentBreak();

        if (this._mode === SESSION_MODE) {
            if (testBreakObj === undefined || testBreakObj === null) {
                this._tickRunTransitionToDone();
            } else {
                this._tickRunTransitionFromSessionToBreak();
            }
        }
        else if (this._mode === BREAK_MODE) {
            if (testSessionObj == undefined || testSessionObj === null) {
                this._tickRunTransitionToDone();
            } else {
                this._tickRunTransitionFromBreakToSession();
            }
        } else {
            this._tickRunTransitionToDone();
        }

        return this._status;
    }

    /**
     *  Private function transitions the evaluators state from `session` to
     *  `break` mode. This is a sub-function inside `tick` function.
     */
    _tickRunTransitionFromSessionToBreak() {
        console.log("TICK | Info: Action: Switching from `session` mode to `break` mode.");
        const testBreak = this.getCurrentBreak();
        console.log(testBreak);

        const duration = parseInt(testBreak.durationInMinutes) * 60 * 1000
        this._startCountdownTimerAtTimestamp = Date.now() + duration;
        this._status = TEST_SUBMISSION_BREAK_STATE;
        this._mode = BREAK_MODE;
        this._question = {};

        /*
         *  Since we are transitioning away from our 'session' mode to our new
         *  'break' mode, as a result we will need to increment the
         *  `_sessionIndex` property because we are finished with this old
         *  session we have.
         */
        this._sessionIndex += 1;

        this._saveDehydrateStore(); // Save to persistent storage.
    }

    /**
     *  Private function transitions the evaluators state from `break` to
     *  `session` mode. This is a sub-function inside `tick` function.
     */
    _tickRunTransitionFromBreakToSession() {
        console.log("TICK | Info: Action: Switching from `break` mode to `session` mode.");
        const testSessionObj = this.getCurrentTestSession();
        console.log(testSessionObj);

        const duration = parseInt(testSessionObj.durationInMinutes) * 60 * 1000
        this._startCountdownTimerAtTimestamp = Date.now() + duration;
        this._status = TEST_SUBMISSION_IN_PROGRESS_STATE;
        this._mode = SESSION_MODE;
        this._question = first(testSessionObj.questions);

        /*
         *  Since we are transitioning away from our 'break' mode to our new
         *  'session' mode, as a result we will need to increment the
         *  `_breakIndex` property because we are finished with this old
         *  break we have.
         */
        this._breakIndex += 1;

        this._saveDehydrateStore(); // Save to persistent storage.
    }

    /**
     *  Private function transitions the evaluators state from `session` to
     *  `done` mode. This is a sub-function inside `tick` function.
     */
    _tickRunTransitionToDone() {
        console.log("TICK | Info: Action: Switching to `done` mode.");
        this._status = TEST_SUBMISSION_COMPLETED_STATE;
        this._endAtTimestamp = Date.now();
        this._endAt = getAwareNowDateTimeString(this._test.timezone);
        this._saveDehydrateStore(); // Save to persistent storage.
    }

    /**
     *  Function will load the next question.
     */
    startNextQuestion() {
        // CASE 1: LAST QUESTION IN TEST / SESSION
        // If we are at the last question then do the following.
        if (this.isLastQuestionInTest() || this.isLastQuestionInSession()) {
            console.log("startNextQuestion | Log | We are finished.")
            this._answers[this._pickedSlug] = {
                letter: this._pickedLetter,
                psuedoLetter: this._pickedPsuedoLetter,
                startAt: this._pickedStartAt,
                endAt: getAwareNowDateTimeString(this._test.timezone),
                subjectName: this._pickedSubjectName,
                order: this._pickedOrderNum,
            };
            this._question = "";
            this._pickedSlug = "";
            this._pickedLetter = "";
            this._pickedPsuedoLetter = "";
            this._pickedStartAt = getAwareNowDateTimeString(this._test.timezone);
            this._pickedSubjectName = "";
            this._pickedOrderNum = "";
            this._pickedSubjectName = "";
            this._pickedOrderNum = "";
            this._saveDehydrateStore(); // Save to persistent storage.
            return false;
        }

        // CASE 2: NOT LAST QUESTION IN TEST / SESSION.
        // If we are not at the last question then iterate over the questions and
        // find the next question.
        // ...

        let currentFound = false;
        const currentSession = this.getCurrentTestSession();
        for (let question of currentSession.questions) {
            if (currentFound) {
                this._answers[this._pickedSlug] = {
                    letter: this._pickedLetter,
                    psuedoLetter: this._pickedPsuedoLetter,
                    startAt: this._pickedStartAt,
                    endAt: getAwareNowDateTimeString(this._test.timezone),
                    subjectName: this._pickedSubjectName,
                    order: this._pickedOrderNum,
                };
                this._question = question;
                this._pickedSlug = "";
                this._pickedLetter = "";
                this._pickedPsuedoLetter = "";
                this._pickedStartAt = getAwareNowDateTimeString(this._test.timezone);
                this._pickedSubjectName = "";
                this._pickedOrderNum = "";
                this._saveDehydrateStore(); // Save to persistent storage.
                return true;
            }
            if (question.slug === this._question.slug) {
                currentFound = true;
            }
        }

        return false
    }

    /**
     *  Function saves the choice option for the particular question.
     */
    setAnswer(slug, letter, psuedoLetter) {
        this._pickedSlug = slug;
        this._pickedLetter = letter;
        this._pickedPsuedoLetter = psuedoLetter;
        this._pickedSubjectName = this._question.subject.name;
        this._pickedOrderNum = this._question.order;
        this._saveDehydrateStore(); // Save to persistent storage.
        return this.getUploadableContent()
    }

    /**
     *  Function will translate the `answers` to be structured in a such a
     *  manner that the answers can be rendered in a PDF table provided by
     *  the "@david.kucsai/react-pdf-table" library.
     */
    getReceiptAnswers() {
        const newAnswers = [];
        for(const answerUuid in this._answers) {
            let answerObj = this._answers[answerUuid];
            answerObj['slug'] = answerUuid;
            var diffDays = answerObj.endAt - answerObj.startAt;

            const endAt = new Date(answerObj.endAt);
            const startAt = new Date(answerObj.startAt);
            const diffTime = Math.abs(startAt - endAt);
            const diffSeconds = Math.ceil(diffTime / (1000));

            let minutes = Math.floor(diffSeconds / 60);
            let seconds = Math.floor(diffSeconds % 60);

            answerObj['choice'] = answerObj.psuedoLetter + "("+answerObj.letter+")";
            answerObj['duration'] = minutes + ":" + seconds;
            newAnswers.push(answerObj);
        }
        return newAnswers
    }

    /**
     *  Generates a receipt output which is used by the receipt PDF generator.
     *  Please see the following code repository via
     *  https://github.com/London-Language-Institute/mktester-frontend-pdfgen
     */
    getReceipt() {
        // const endAt = new Date(answerObj.endAt);
        // const startAt = new Date(answerObj.startAt);
        // const diffTime = Math.abs(startAt - endAt);
        // const diffSeconds = Math.ceil(diffTime / (1000));
        // answerObj['choice'] = answerObj.psuedoLetter + "("+answerObj.letter+")";
        // answerObj['duration'] = diffSeconds+" sec";
        // newAnswers.push(answerObj);

        return {
            "logoRightURL": "/logo2.png",
            "logoLeftURL": "/footer1.png",
            "footerURL": "/footer.png",
            "studentName": this._testSubmission.student.name,
            "studentId": this._testSubmission.student.id,
            "grade": this._testSubmission.student.grade,
            "collegeCode": this._testSubmission.student.collegeCode,
            "city": this._testSubmission.student.city,
            "state": "Completed",
            "startedAt": this._startAt && this._startAt.toLocaleString(),
            "finishedAt": this._startAt && this._endAt.toLocaleString(),
            "duration": this.getElapsedTimeInMinutes(),
            "test": this.getTest(),
            "answers": this.getReceiptAnswers(),
            "uploadContent": this.getBase64ReceiptString(),
            "isLate": this.getIsLate(),
            "status": this.getStatus(),
        };
    }

    /**
     *  Return the picked slug for the option the student selected.
     */
    getPickedAnswerSlug() {
        return this._pickedSlug;
    }

    /**
     *  Set the state of the test to be complete.
     */
    complete() {
        this._tickRunTransitionToDone();
    }

    /**
     *  Function will produce an output which is to be used for submission into
     *  the API web-server. Please note this is a proprietery format. Function
     *  will also save the results locally.
     */
    getUploadableContent() {
        // if (this._status !== TEST_SUBMISSION_COMPLETED_STATE) {
        //     alert("You cannot call this function until you answer last question.");
        // }
        const content = [];
        content.push(this._startAt);
        content.push(this._endAt);
        const subContent = [];
        for (let answerSlug in this._answers) {
            let answer = this._answers[answerSlug];
            subContent.push([
                answer.letter,
                answer.startAt,
                answer.endAt,
                answerSlug
            ]);
        }
        content.push(subContent);
        content.push(this.getIsLate());
        this._uploadContent = content;
        this._saveDehydrateStore(); // Save to persistent storage.
        return content;
    }

    getBase64ReceiptString() {
        const contentDict = {
            testSubmissionUuid: this._testSubmission.testSubmissionUuid,
            answerContent: this.getUploadableContent(),
            isLate: this.getIsLate(),
            status: this.getStatus(),
        };

        // Special thanks:
        // Base64 encode a javascript object via https://stackoverflow.com/a/38134388
        let objJsonStr = JSON.stringify(contentDict);
        let objUTF8JsonStr = forceUnicodeEncoding(objJsonStr);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
        return objJsonB64;
    }

    /**
     *  Return whether to submit to API or not.
     */
    getWasSubmittedToAPI() {
        return this._wasSubmittedToAPI;
    }

    /**
     *  Set whether we submitted to the API or not.
     */
    setWasSubmittedToAPI(value) {
        this._wasSubmittedToAPI = value;
        this._saveDehydrateStore();
    }

    getIsLate() {
        if (this.hasOwnProperty("_isLate")) {
            return this._isLate === true;
        }
        return false;
    }

    setIsLate(value) {
        this._isLate = value;
        this._saveDehydrateStore();
    }
}
