import React from "react";


export default function CompleteComponent(props) {
    return(
        <div>
          Welcome to MarkingCloud
        </div>

    );
}
