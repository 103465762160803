import React from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Alert from 'react-bootstrap/Alert';
import { Offline, Online } from "react-detect-offline";
import Moment from 'react-moment';
import 'moment-timezone';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';

import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";


export default function LaunchpadComponent({ errorId, errorMessage, errorTestName}) {
    return(
        <Container>
            <TopNavigationComponent
                title="Cargando"
                year="2040"
            />
            <div style={{marginTop: 120}} />
            <Offline>
                <Alert key={"offline-banner"} variant="warning">
                    <strong>Modo sin conexión:</strong>&nbsp;En este momento no se ha detectado conexión a internet. Usted no podrá enviar la prueba hasta que vuelva a contar con conexión a internet.
                </Alert>
            </Offline>

            <Jumbotron>
                <h1>Cargando</h1>
                <p>
                    Cargando ... Cargando por favor espere.
                </p>

            </Jumbotron>

            <Footer />
        </Container>
    );
}
