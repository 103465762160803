import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/**
 *  The purpose of this model is to provide enough information to the students
 *  to get them having an understanding of their progress in the test submission
 *  and for Ceinfes to have enough details to diagnose problems.
 */
export default function InfoModalComponent(props) {
    const { showInfoModal, onInfoCloseClick, onInfoOKClick } = props;
    return (
        <Modal show={showInfoModal} onHide={onInfoCloseClick}>
            <Modal.Header closeButton>
                <Modal.Title><span><FontAwesomeIcon icon={faDownload} />&nbsp;Instantánea Prueba</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                La siguiente información es útil en los problemas de diagnóstico de Ceinfes. Si tiene algún problema, copie y pegue este código.
            </Modal.Body>
            <Modal.Body></Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={onInfoCloseClick}>
                <span><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Cancelar</span>
              </Button>
              <Button variant="success" onClick={onInfoOKClick}>
                <span><FontAwesomeIcon icon={faDownload} />&nbsp;Descargar</span>
              </Button>
            </Modal.Footer>
        </Modal>
    );
}
