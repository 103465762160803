import isEmpty from "lodash/isEmpty";

import {
    localStorageGetObjectItem, localStorageSetObjectOrArrayItem
} from "./localStorageUtility";


export default function getOrCreateRandomizeQuestion(originalQuestion) {

    const cachedRandomizedQuestion = localStorageGetObjectItem(originalQuestion.slug);
    if (isEmpty(cachedRandomizedQuestion)) {
        return getAndCreateRandomQuestion(originalQuestion);
    } else {
        return cachedRandomizedQuestion;
    }
}


/**
 *  Function will do the following:
 *  (1) Clone the question so it does not impact original question.
 *  (2) Shuffle the question choices.
 *  (3) Assign a psuedo choice letter which user will see but will not
 *      see the original choice letter.
 */
function getAndCreateRandomQuestion(originalQuestion) {
    // Define the special choices we have available.
    const psuedoChoiceLetters = [
        "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N",
        "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    ];

    // Clone the objects we will be modifying in this function. Skipping this
    // step will result in unintended impact on our original question.
    // NOTE: Use "Deep clone" (see via https://stackoverflow.com/a/39736431) to
    // make sure we have a complete copy and not a shallow copy.
    const clonedQuestion = JSON.parse(JSON.stringify(originalQuestion));

    // Run the code which will randomize the array of available choices.
    shuffleArray(clonedQuestion.options);

    // Iterate over our newly randomized choices and assign a psuedo choice
    // letter that should be displayed to the user.
    const newOptions = [];
    for (let i = 0; i < clonedQuestion.options.length; i++) {
        let option = clonedQuestion.options[i];
        let psuedoChoiceLetter = psuedoChoiceLetters[i];
        let newOption = Object.assign({
            psuedoOptionLetter: psuedoChoiceLetter,
        }, option);
        newOptions.push(newOption);
    }

    clonedQuestion.options = newOptions;

    // // For debugging purposes only.
    // console.log(originalQuestion);
    // console.log(clonedQuestion);
    // console.log("\n\n");

    // Save our randomized question and then return it.
    localStorageSetObjectOrArrayItem(clonedQuestion.slug, clonedQuestion);
    return clonedQuestion;
}


/*
 * Randomize array in-place using Durstenfeld shuffle algorithm.
 * LINK: https://stackoverflow.com/a/12646864
 */
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}
