import React, { Component } from "react";

import ErrorTwelveComponent from "../../../components/general/customErrors/errorTwelveComponent";


export default class ErrorTwelveContainer extends Component {
    constructor(props) {
        super(props);

        // Extract the `query parameter` from the URL.
        // Special thanks: https://stackoverflow.com/a/43220620
        const params = new URLSearchParams(props.location.search);
        const errorId = params.get('errorId');
        const errorMessage = params.get('errorMessage');
        const errorTestName = localStorage.getItem("ErrorTestName");

        this.state = {
            errorId: errorId,
            errorMessage: errorMessage,
            errorTestName: errorTestName,

        };
    }

    render() {
        const { errorId, errorMessage, errorTestName } = this.state;
        return (
            <ErrorTwelveComponent
                errorTestName={errorTestName}
            />
        );
    }
}
