import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faExclamationCircle, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function AbandonModalComponent(props) {
    const { showAbandonModal, onAbandonCloseClick, onAbandonOKClick } = props;
    return (
        <Modal show={showAbandonModal} onHide={onAbandonCloseClick}>
            <Modal.Header closeButton>
              <Modal.Title><span><FontAwesomeIcon icon={faExclamationCircle} />&nbsp;Confirmación</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Ten presente que abandonarás la prueba sin completar todas sus preguntas y luego no podrás continuar con la aplicación.</p>
            <p>¿Estás seguro de abandonadar la prueba?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={onAbandonCloseClick}>
                <span><FontAwesomeIcon icon={faTimesCircle} />&nbsp;Cancelar</span>
              </Button>
              <Button variant="success" onClick={onAbandonOKClick}>
                <span><FontAwesomeIcon icon={faCheckCircle} />&nbsp;Aceptar</span>
              </Button>
            </Modal.Footer>
        </Modal>
    );
}
