export const PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST = 'PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST';
export const PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE = 'PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE';
export const PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS = 'PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const TEST_SUBMISSION_DETAIL_REQUEST = 'TEST_SUBMISSION_DETAIL_REQUEST';
export const TEST_SUBMISSION_DETAIL_FAILURE = 'TEST_SUBMISSION_DETAIL_FAILURE';
export const TEST_SUBMISSION_DETAIL_SUCCESS = 'TEST_SUBMISSION_DETAIL_SUCCESS';

export const QUESTION_SUBMISSION_DETAIL_REQUEST = 'QUESTION_SUBMISSION_DETAIL_REQUEST';
export const QUESTION_SUBMISSION_DETAIL_FAILURE = 'QUESTION_SUBMISSION_DETAIL_FAILURE';
export const QUESTION_SUBMISSION_DETAIL_SUCCESS = 'QUESTION_SUBMISSION_DETAIL_SUCCESS';

export const TEST_SUBMISSION_TIME_SYNC_REQUEST = 'TEST_SUBMISSION_TIME_SYNC_REQUEST';
export const TEST_SUBMISSION_TIME_SYNC_FAILURE = 'TEST_SUBMISSION_TIME_SYNC_FAILURE';
export const TEST_SUBMISSION_TIME_SYNC_SUCCESS = 'TEST_SUBMISSION_TIME_SYNC_SUCCESS';

export const TEST_DETAIL_REQUEST = 'TEST_DETAIL_REQUEST';
export const TEST_DETAIL_FAILURE = 'TEST_DETAIL_FAILURE';
export const TEST_DETAIL_SUCCESS = 'TEST_DETAIL_SUCCESS';
