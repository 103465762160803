import axios from 'axios';
import store from '../store';
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';
import isEmpty from 'lodash/isEmpty';
import msgpack from 'msgpack-lite';

import {
    TEST_DETAIL_REQUEST,
    TEST_DETAIL_FAILURE,
    TEST_DETAIL_SUCCESS
} from '../constants/actionTypes';
import {
    MCL_TEST_DETAIL_API_ENDPOINT,
} from '../constants/api';
import getCustomAxios from '../helpers/customAxios';


////////////////////////////////////////////////////////////////////////////////
//                               OPERATIONS                                   //
////////////////////////////////////////////////////////////////////////////////


export function pullTest(testSubmissionUuid, onSuccessCallback, onFailureCallback) {
    return dispatch => {
        // Change the global state to attempting to fetch latest user details.
        store.dispatch(
            setTestDetailRequest()
        );

        const customAxios = getCustomAxios();

        const aURL = MCL_TEST_DETAIL_API_ENDPOINT.replace("<testSubmissionUuid>", testSubmissionUuid);

        customAxios.get(aURL).then( (successResponse) => { // SUCCESS
            // Decode our MessagePack (Buffer) into JS Object.
            const responseData = msgpack.decode(Buffer(successResponse.data));
            let test = camelizeKeys(responseData);

            // Extra.
            test['isAPIRequestRunning'] = false;
            test['errors'] = {};

            // Update the global state of the application to store our
            // user test for the application.
            store.dispatch(
                setTestDetailSuccess(test)
            );

            // DEVELOPERS NOTE:
            // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
            // OBJECT WE GOT FROM THE API.
            if (onSuccessCallback) {
                onSuccessCallback(test);
            }

        }).catch( (exception) => { // ERROR
            if (exception.response) {
                const responseBinaryData = exception.response.data; // <=--- NOTE: https://github.com/axios/axios/issues/960

                // Decode our MessagePack (Buffer) into JS Object.
                const responseData = msgpack.decode(Buffer(responseBinaryData));

                let errors = camelizeKeys(responseData);

                console.log("pullTest | error:", errors); // For debuggin purposes only.

                // Send our failure to the redux.
                store.dispatch(
                    setTestDetailFailure({
                        isAPIRequestRunning: false,
                        errors: errors
                    })
                );

                // DEVELOPERS NOTE:
                // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
                // OBJECT WE GOT FROM THE API.
                if (onFailureCallback) {
                    onFailureCallback(errors);
                }
            }

        }).then( () => { // FINALLY
            // Do nothing.
        });

    }
}




////////////////////////////////////////////////////////////////////////////////
//                                REDUX ACTIONS                               //
////////////////////////////////////////////////////////////////////////////////



export const setTestDetailRequest = () => ({
    type: TEST_DETAIL_REQUEST,
    payload: {
        isAPIRequestRunning: true,
        errors: {}
    },
});


export const setTestDetailSuccess = testDetail => ({
    type: TEST_DETAIL_SUCCESS,
    payload: testDetail,
});


export const setTestDetailFailure = testDetail => ({
    type: TEST_DETAIL_FAILURE,
    payload: testDetail,
});



////////////////////////////////////////////////////////////////////////////////
//                                 UTILITY                                    //
////////////////////////////////////////////////////////////////////////////////

/**
 * Utility function takes the API data and converts it to HTML dropdown
 * options which will be consumed by the `react-select` library elements.
 */
export function getTestReactSelectOptions(testList=[], selectName="test") {
    const testOptions = [];
    const isNotProductionsEmpty = isEmpty(testList) === false;
    if (isNotProductionsEmpty) {
        const results = testList.results;
        const isResultsNotEmpty = isEmpty(results) === false;
        if (isResultsNotEmpty) {
            for (let i = 0; i < results.length; i++) {
                let test = results[i];
                testOptions.push({
                    selectName: selectName,
                    value: test.slug,
                    label: test.fullName
                });
                // console.log(test);
            }
        }
    }
    return testOptions;
}
