import React from "react";
import Container from 'react-bootstrap/Container';

import '../../App.css';
import error500 from '../../img/error500.jpeg';


export default function ServerError500Component(props){

    return(
      <Container fluid>
          <span className="footer-copyright text-center py-3" style={{bottom:"0"}}>
              <img src={error500} width="1338vh"  />
          </span>
      </Container>

        );
}
