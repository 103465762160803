import React, { Fragment } from "react";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';


export default function TopNavigationComponent({ title, year }) {
    return(
        <Navbar fixed="top" style={{backgroundColor:"#14405B"}}>
            <Navbar.Brand className="logo-container">
                <img src="https://cdn.jsdelivr.net/gh/ceinfestecnologia/Esqueleto_AppPrueba@master/logo.png"  width="85px" height="62px" />
            </Navbar.Brand>
            <span className="navbar-text text-white text-nav-title flex-fill">
                {title}
            </span>
            <span id="fecha" style={{color:"grey"}} className="flex-shrink-1 navbar-text text-nav-fecha">
                <span>{year}</span> {/* Colour and size in date has to be changed */}
            </span>
        </Navbar>
    );
}
