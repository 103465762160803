import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import isEmpty from "lodash/isEmpty";
import { Redirect } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

import TestSubmissionCompleteSendingOnlineComponent from "../../components/testSubmission/completeSendingOnlineComponent";
import TestSubmissionCompleteSendingOfflineComponent from "../../components/testSubmission/completeSendingOfflineComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import getBrowserMetrics from '../../helpers/browserMetricsHelper';
import { postFinishTestSubmission } from "../../actions/testSubmissionActions";
import { setAccessTokenInLocalStorage } from '../../helpers/tokenUtility';


class TestSubmissionCompleteSendingContainer extends Component {
    constructor(props) {
        super(props);
        const evaluatorStore = getEvaluatorStoreInstance();

        // For diagnostic purposes, attach some browser details to help the
        // programmers diagnose problems pertaining to test submissions.
        let browserMetrics = getBrowserMetrics();

        // The following code will generate the output data which will be loaded
        // into the PDF receipt generator for our PDF creation document.
        const receipt = evaluatorStore.getReceipt();
        localStorage.setItem("MCL_RECEIPT", JSON.stringify(receipt)); // Useful to store temporarily.

        this.state = {
            isLoading: evaluatorStore.getWasSubmittedToAPI() === false,
            errors: {},
            evaluatorStore: evaluatorStore,
            browserMetrics: browserMetrics,
            receipt: receipt,
        };

        this.onInternetConnectionChange = this.onInternetConnectionChange.bind(this);
        this.onPOSTSuccessCallback = this.onPOSTSuccessCallback.bind(this);
        this.onPOSTFailedCallback = this.onPOSTFailedCallback.bind(this);
    }

    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // CASE 1: Was not submitted on time.
        let wasSubmitted = this.state.evaluatorStore.getWasSubmittedToAPI();

        // CASE 2: Completed and not submutted.
        if (wasSubmitted === undefined) {
            if (this.state.evaluatorStore.getStatus() === 4) {
                /*
                 * We need to set the value to be valse to force the client to
                 * submit the answers, regardless how much they finished.
                 */
                wasSubmitted = false;
                const accessToken = this.state.evaluatorStore.getAccessToken();
                setAccessTokenInLocalStorage(accessToken);
            }
        }

        // Make API call if we did not send it previously.
        if (wasSubmitted === false || wasSubmitted === "false") {
            this.props.postFinishTestSubmission(
                {
                    testSubmissionUuid: this.state.evaluatorStore.getTestSubmissionUuid(),
                    answers: this.state.evaluatorStore.getUploadableContent(),
                    browserMetrics: this.state.browserMetrics,
                },
                this.onPOSTSuccessCallback,
                this.onPOSTFailedCallback
            )
        } else {
            // Redirected to the last page.
            this.setState({
                isLoading: false,
                errors: {},
                forceURL: "/submission/completed"
            });
        }
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    onPOSTSuccessCallback(test) {
        console.log("TestSubmissionCompleteSendingContainer | onPOSTSuccessCallback | Log");
        console.log(test, "\n\n");
        this.state.evaluatorStore.setWasSubmittedToAPI(true);

        // Redirected to the last page.
        this.setState({
            isLoading: false,
            errors: {},
            forceURL: "/submission/completed"
        });
    }

    onPOSTFailedCallback(errors, status) {
        console.log("TestSubmissionCompleteSendingContainer | Log\n");
        console.log("onPOSTFailedCallback |", errors, "|", status,"\n\n");

        if (status === 500) {
            // this.setState({ isLoading: false, errors: {
            //     // A server error occurred when submitting the test, please try again later.
            //     'nonFieldError': '¡Ha ocurrido un error al enviar la prueba! Vuelve a intentarlo más tarde.',
            // }, });
            this.props.history.push("/500");
        }
        else if (status === 400) {
            this.props.history.push("/400?errorId=0&errorMessage="+errors);
            return;
        } else if (status === 403) {
            this.setState({ isLoading: false, errors: {
                // Your session has expired, please log in before submitting your test.
                'nonFieldError': "¡Tu sesión ha expirado! Inicia sesión para enviar tus resultados",
            }, });
        } else {
            this.setState({ isLoading: false, errors: {
                // An unknown error has occured, please contact the system administrator.
                'nonFieldError': "¡Ha ocurrido un error desconocido! Ponte en contacto con el administrador del sistema.",
            }, });
        }
    }

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    onInternetConnectionChange() {
        // CASE 1: Was not submitted on time.
        let wasSubmitted = this.state.evaluatorStore.getWasSubmittedToAPI();

        // CASE 2: Completed and not submutted.
        if (wasSubmitted === undefined) {
            if (this.state.evaluatorStore.getStatus() === 4) {
                /*
                 * We need to set the value to be valse to force the client to
                 * submit the answers, regardless how much they finished.
                 */
                wasSubmitted = false;
                const accessToken = this.state.evaluatorStore.getAccessToken();
                setAccessTokenInLocalStorage(accessToken);
            }
        }

        // Make API call if we did not send it previously.
        if (wasSubmitted === false || wasSubmitted === "false") {
            this.props.postFinishTestSubmission(
                {
                    testSubmissionUuid: this.state.evaluatorStore.getTestSubmissionUuid(),
                    answers: this.state.evaluatorStore.getUploadableContent(),
                    browserMetrics: this.state.browserMetrics,
                },
                this.onPOSTSuccessCallback,
                this.onPOSTFailedCallback
            )
        } else {
            // Redirected to the last page.
            this.setState({
                isLoading: false,
                errors: {},
                forceURL: "/submission/completed"
            });
        }
    }

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const { evaluatorStore, errors, isLoading, forceURL } = this.state;

        /**
         *  If the countdown timer ran out or the user finished before the
         *  countdown timer finshes then redirect to a different page.
         */
        if (forceURL !== undefined && forceURL !== null && forceURL !== "") {
            return <Redirect to={forceURL} />;
        }

        return (
            <Fragment>
                <Offline onChange={this.onInternetConnectionChange}>
                    <TestSubmissionCompleteSendingOfflineComponent
                        errors={errors}
                        isLoading={isLoading}
                        testSubmission={evaluatorStore._testSubmission}
                        test={evaluatorStore._testSubmission.test}
                        student={evaluatorStore._testSubmission.student}
                        session={evaluatorStore._session }
                        name={evaluatorStore._testSubmission.test.name}
                        type={evaluatorStore._testSubmission.test.type}
                        status={evaluatorStore.getStatus()}
                    />
                </Offline>
                <Online>
                    <TestSubmissionCompleteSendingOnlineComponent
                        errors={errors}
                        isLoading={isLoading}
                        testSubmission={evaluatorStore._testSubmission}
                        test={evaluatorStore._testSubmission.test}
                        student={evaluatorStore._testSubmission.student}
                        session={evaluatorStore._session }
                        name={evaluatorStore._testSubmission.test.name}
                        type={evaluatorStore._testSubmission.test.type}
                        status={evaluatorStore.getStatus()}
                    />
                </Online>
            </Fragment>
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testObj: store.testState,
        testSubmissionObj: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        postFinishTestSubmission: (data, onPOSTSuccessCallback, onPOSTFailedCallback) => {
            dispatch(postFinishTestSubmission(data, onPOSTSuccessCallback, onPOSTFailedCallback))
        },
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSubmissionCompleteSendingContainer);
