import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

import TestSubmissionAgreementComponent from "../../components/testSubmission/agreementComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import { postBeginTestSubmission } from "../../actions/testSubmissionActions";
import getBrowserMetrics from '../../helpers/browserMetricsHelper';


class TestSubmissionAgreementContainer extends Component {

    /**
     *  Initializer
     *------------------------------------------------------------
     */

    constructor(props) {
        super(props);

        const evaluatorStore = getEvaluatorStoreInstance();

        this.state = {
            isDisabled: true,
            errors: {},
            evaluatorStore: evaluatorStore,
        };
        this.onClick = this.onClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onPOSTSuccessCallback = this.onPOSTSuccessCallback.bind(this);
        this.onPOSTFailedCallback = this.onPOSTFailedCallback.bind(this);
    }

    /**
     *  Utility
     *------------------------------------------------------------
     */


    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    componentDidUpdate(nextProps) {
        const { isReadyToBegin, testInitializerErrorMessage } = nextProps;
        if (isReadyToBegin != this.state.isReadyToBegin) {
            this.setState({
                isReadyToBegin: isReadyToBegin,
            });
        }
        if (testInitializerErrorMessage !== "" && isEmpty(this.state.errors)) {
            this.setState({
                errors: {
                    nonFieldError: testInitializerErrorMessage,
                },
            });
        }
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    onPOSTSuccessCallback(test) {
        console.log("TestSubmissionAgreementContainer | onPOSTSuccessCallback | Log");
        console.log(test, "\n\n");

        // Update the application model so we know the test begun.
        const evaluatorStore = getEvaluatorStoreInstance();
        evaluatorStore.begin();

        // Change the page.
        this.props.history.push("/submission/question");
    }

    onPOSTFailedCallback(errors, status) {
        console.log("TestSubmissionAgreementContainer | Log\n");
        console.log("onPOSTFailedCallback |", errors, "|", status,"\n\n");

        if (status === 500) {
            // this.setState({ isLoading: false, errors: {
            //     // A server error occurred when submitting the test, please try again later.
            //     'nonFieldError': '¡Ha ocurrido un error al enviar la prueba! Vuelve a intentarlo más tarde.',
            // }, });
            this.props.history.push("/500");
        }
        else if (status === 400) {
            this.props.history.push("/400?errorId=0&errorMessage="+errors);
            return;
        } else if (status === 403) {
            this.setState({ isLoading: false, errors: {
                // Your session has expired, please log in before submitting your test.
                'nonFieldError': "¡Tu sesión ha expirado! Inicia sesión para enviar tus resultados",
            }, });
        } else {
            this.setState({ isLoading: false, errors: {
                // An unknown error has occured, please contact the system administrator.
                'nonFieldError': "¡Ha ocurrido un error desconocido! Ponte en contacto con el administrador del sistema.",
            }, });
        }
    }

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    onCheckboxChange(evt) {
        this.setState({
            isDisabled: evt.target.checked === false,
        });
    }

    onClick(event) {
        event.preventDefault();

        // For diagnostic purposes, attach some browser details to help the
        // programmers diagnose problems pertaining to test submissions.
        let browserMetrics = getBrowserMetrics();

        this.props.postBeginTestSubmission(
            {
                testSubmissionUuid: this.state.evaluatorStore.getTestSubmissionUuid(),
                browserMetrics: browserMetrics,
            },
            this.onPOSTSuccessCallback,
            this.onPOSTFailedCallback
        )
        // this.props.history.push("/submission/start");
    }

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const { isDisabled, errors, isReadyToBegin } = this.state;

        return (
            <TestSubmissionAgreementComponent
                isDisabled={isDisabled}
                onCheckboxChange={this.onCheckboxChange}
                testSubmission={this.props.testSubmission}
                test={this.props.test}
                isLoading={isReadyToBegin}
                errors={errors}
                onClick={this.onClick}
            />
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testSubmission: store.testSubmissionState,
        test: store.testState,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        postBeginTestSubmission: (data, onPOSTSuccessCallback, onPOSTFailedCallback) => {
            dispatch(postBeginTestSubmission(data, onPOSTSuccessCallback, onPOSTFailedCallback))
        },
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSubmissionAgreementContainer);
