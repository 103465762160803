import React, { Fragment } from "react";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Countdown from 'react-countdown';
import moment from 'moment'
import Moment from 'react-moment';
import 'moment-timezone';


export default function TopNavigationWithCountdownTimerComponent({
    title, studentName, sessionName, startCountdownTimerAtTimestamp,
    questionCurrentPosition, questionsCount, timezone, onCountdownTimerCompletion,
    onCountdownTimerTick
}) {
    /**
     * Special thanks:
     * https://github.com/ndresx/react-countdown
     * -------------------------------------------------------------------------
     */

    // Random component
    const Completionist = () => <span>¡Tiempo Agotado!</span>; // Test finished!

    // Renderer callback with condition
    const renderer = ({ formatted, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{formatted.hours}:{formatted.minutes}:{formatted.seconds}</span>;
        }
    };

    return(
        <Navbar fixed="top" className="justify-content-between" style={{backgroundColor:"#14405B"}}>
            <Navbar.Brand className="logo-container">
                <img src="https://cdn.jsdelivr.net/gh/ceinfestecnologia/Esqueleto_AppPrueba@master/logo.png"  width="85px" height="62px" alt="Logo" />
            </Navbar.Brand>
            <span className="text-white text-nav-fecha d-none d-sm-block">{title}<br />{studentName}</span>
            <div className="header-tool">
                <div className="header-tool-item">
                    <h6 className="text-primary">{sessionName}</h6>
                    {questionCurrentPosition === 1 && questionsCount === 1
                        ? <span className="text-success">-</span>
                        : <span className="text-success">{questionCurrentPosition} preguntas de {questionsCount}</span>
                    }
                </div>
                <div className="header-tool-item">
                    <h6 className="text-primary">¡Faltan!</h6>
                    <span className="text-success">
                        <Countdown
                            onComplete={onCountdownTimerCompletion}
                            onTick={onCountdownTimerTick}
                            date={startCountdownTimerAtTimestamp}
                            renderer={renderer}
                        />
                    </span>
                </div>
            </div>
        </Navbar>
    );
}
