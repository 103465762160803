import axios from 'axios';
import { camelizeKeys } from 'humps';
import { MCL_REFRESH_TOKEN_API_URL } from "../constants/api"

/**
 *------------------------------------------------------------------------------
 * The purpose of this utility is to handle all API token related functionality
 * and provide an interface for the system to use.
 *------------------------------------------------------------------------------
 */


/**
 *  Saves our access token to persistent storage.
 */
export function setAccessTokenInLocalStorage(accessToken) {
    localStorage.setItem("MCL_TOKEN_UTILITY_ACCESS_TOKEN_DATA", accessToken);
}


/**
 *  Saves our refresh token to our persistent storage.
 */
export function setRefreshTokenInLocalStorage(accessToken) {
    localStorage.setItem("MCL_TOKEN_UTILITY_REFRESH_TOKEN_DATA", accessToken);
}


/**
 *  Gets our access token from persistent storage.
 */
export function getAccessTokenFromLocalStorage() {
    return localStorage.getItem("MCL_TOKEN_UTILITY_ACCESS_TOKEN_DATA");
}


/*
 *  Gets our refresh token from persisten storage.
 */
export function getRefreshTokenFromLocalStorage() {
    return localStorage.getItem("MCL_TOKEN_UTILITY_REFRESH_TOKEN_DATA");
}
