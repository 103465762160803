import React, { Component } from "react";
import { connect } from 'react-redux';
import isEmpty from "lodash/isEmpty";
import { PDFViewer } from '@react-pdf/renderer';

import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import { PDFViewComponent } from "../../components/testSubmission/pdfViewComponent";


class PDFViewContainer extends Component {
    constructor(props) {
        super(props);
        const evaluatorStore = getEvaluatorStoreInstance();
        const receipt = {
            "studentName": "Frank Herbert",
        };
        console.log(receipt);

        this.state = {
            isLoading: evaluatorStore.getWasSubmittedToAPI() === false,
            errors: {},
            evaluatorStore: evaluatorStore,
            receipt: receipt,
        };
    }

    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */


    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const { evaluatorStore, errors, isLoading, receipt } = this.state;
        return (
            <PDFViewer>
                <PDFViewComponent fileName="download.pdf" />
            </PDFViewer>
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testObj: store.testState,
        testSubmissionObj: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PDFViewContainer);
