import {
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST,
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS,
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
} from '../constants/actionTypes';


const testSubmissionReducer = function(state = [], action = {}) {
    switch (action.type) {
        case PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS:
            return Object.assign({}, state, action.payload);

        case PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE:
            return Object.assign({}, state, action.payload);

        case PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST:
            return Object.assign({}, state, action.payload);

        case LOGOUT_SUCCESS:
            return {}; // Note: Setting to empty dict clears the state.

        case LOGOUT_FAILURE:
            return Object.assign({}, state, action.payload);

        case LOGOUT_REQUEST:
            return Object.assign({}, state, action.payload);

        default:
           return state;
    }
}

export default testSubmissionReducer;
