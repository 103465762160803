import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import CardDeck from 'react-bootstrap/CardDeck';
import Moment from 'react-moment';
import 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faClock, faLink, faUserCircle, faBook, faReceipt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ScaleLoader } from 'react-spinners';

import Footer from '../common/footerComponent';
import TopNavigationComponent from "../navigation/topNavigationComponent";


// Import image for the button //
// CSS code from Colombian team to match it with our design //

export default function TestSubmissionCompleteSendingOnlineComponent(props) {
    const {
        testSubmission, errors, isLoading, test, student,
        session, name, type, status
    } = props;
    return(
      <Container>
          <TopNavigationComponent
              title={testSubmission && testSubmission.test && testSubmission.test.name}
              year={testSubmission && testSubmission.test && testSubmission.test.year}
          />
          <Row>
              <Col><br /><br /><br /><br /><br /></Col>
              {/*
              <Col className="col-xsm-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" md="auto">
                <h3>¡Tiempo Agotado!</h3>
              </Col>
              */}
          </Row>
          <Row>
              <Col>
                <h3 className="text-center text-secondary mb-3"><i className="fas fa-server fa-5x"></i></h3>
                <h1 className="text-center display-2 text-secondary mb-3">
                <div className="loader">
                <ScaleLoader
                  heightUnit={"px"}
                  widthUnit={"px"}
                  height={35}
                  width={40}
                  radius={2}
                  margin={'2px'}
                  color={'#6c757d'}
                />
                <h2 className="text-center text-secondary mb-3 loader-text" dangerouslySetInnerHTML={{ __html: '' }} />
                </div>

                Enviando...

                </h1>

                <p className="text-center text-secondary lead mb-4">
                  Actualmente estamos enviando sus datos a Ceinfes.
                </p>
              </Col>
          </Row>
          {isEmpty(errors) == false &&
              <Row>
                  <Col>
                  <Alert key={"loading-banner2"} variant="danger">
                  {errors["nonFieldError"]}
                  </Alert>
                  </Col>
              </Row>
          }
           <Footer />
      </Container>
      );
  }
