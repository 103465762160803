import axios from 'axios';
import msgpack from 'msgpack-lite';

import { getPublicAPIBaseURL } from '../helpers/urlUtility';
import { getAccessTokenFromLocalStorage } from '../helpers/tokenUtility';


/**
 *  Function returns a custom `Axios` instance tailered to the `MarkingCloud`
 *  API web-service for authenticated users.
 *
 *  Features:
 *  (1) Inform API to expect request encoded with `MessagePack` format.
 *  (2) Inform API we expect responses to be in `MessagePack` format.
 *  (3) Attach authorization bearer token.
 */
export default function getCustomAxios() {
    // Set our header to use `MessagePack` encoding between our client and
    // the API web-server.
    const header = {
        'Content-Type': 'application/msgpack; charset=UTF-8',
        'Accept': 'application/msgpack',
    };


    // IMPORTANT: THIS IS THE ONLY WAY WE CAN GET THE ACCESS TOKEN.
    const accessToken = getAccessTokenFromLocalStorage();
    if (accessToken !== undefined && accessToken !== null && accessToken !== "") {
        header['Authorization'] = "Bearer " + accessToken;
    }

    // Create a new Axios instance using our oAuth 2.0 bearer token
    // and various other headers.
    const customAxios = axios.create({
        baseURL: getPublicAPIBaseURL(),
        headers: header,
        responseType: 'arraybuffer',
        responseEncoding: 'binary', // <=- https://stackoverflow.com/a/53372599
    });

   // https://github.com/axios/axios/issues/859
    customAxios.defaults.headers['Content-Type'] = 'application/msgpack';


    // Return our custom Axios instance for our application.
    return customAxios;
}
