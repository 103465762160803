import axios from 'axios';
import store from '../store';
import { camelizeKeys } from 'humps';
import msgpack from 'msgpack-lite';

import {
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST,
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE,
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS,
    LOGOUT_SUCCESS
} from "../constants/actionTypes"
import {
    MCL_PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_API_ENDPOINT
} from "../constants/api"
import { setAccessTokenInLocalStorage } from '../helpers/tokenUtility';
import getCustomAxios from '../helpers/customAxios';


export const setLoginRequest = () => ({
    type: PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_REQUEST,
    payload: {
        isAPIRequestRunning: true,
        errors: {}
    },
});


export const setLoginSuccess = payload => ({
    type: PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS,
    payload: payload,
});


export const setLoginFailure = payload => ({
    type: PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_FAILURE,
    payload: payload,
});


export function postPasswordlessLoginFromLaunchpad(postData, successCallback=null, failedCallback=null) {
    return dispatch => {
        // Change the global state to attempting to log in.
        store.dispatch(
            setLoginRequest()
        );

        // Create a new Axios instance which will be sending and receiving in
        // MessagePack (Buffer) format.
        const customAxios = getCustomAxios();

        // Encode from JS Object to MessagePack (Buffer)
        var buffer = msgpack.encode({
            'payload': postData['payload'],
            'browser_metrics': postData['browserMetrics'],
            'frontend_version': postData['frontendVersion'],
        });

        customAxios.post(MCL_PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_API_ENDPOINT, buffer).then( (successResponse) => {
            // // Decode our MessagePack (Buffer) into JS Object.
            const responseData = msgpack.decode(Buffer(successResponse.data));
            // const responseData = successResponse.data;

            // Snake-case from API to camel-case for React.
            let profile = camelizeKeys(responseData);

            console.log("postPasswordlessLoginFromLaunchpad API | Log | Response:");
            console.log(profile);
            console.log("\n");

            // Extra.
            profile['isAPIRequestRunning'] = false;
            profile['errors'] = {};

            // Update the global state of the application to store our
            // user profile for the application.
            store.dispatch(
                setLoginSuccess(profile)
            );

            // SAVE OUR CREDENTIALS IN PERSISTENT STORAGE. THIS IS AN IMPORTANT
            // STEP BECAUSE OUR TOKEN UTILITY HELPER NEEDS THIS.
            // console.log("Token:", profile.student.accessToken);
            setAccessTokenInLocalStorage(profile.student.accessToken);

            // DEVELOPERS NOTE:
            // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
            // OBJECT WE GOT FROM THE API.
            if (successCallback) {
                successCallback(profile);
            }

        }).catch( (exception) => {
            if (exception.response) {
                const responseBinaryData = exception.response.data; // <=--- NOTE: https://github.com/axios/axios/issues/960

                // Decode our MessagePack (Buffer) into JS Object.
                const responseData = msgpack.decode(Buffer(responseBinaryData));

                const errors = camelizeKeys(responseData);

                // Send our failure to the redux.
                store.dispatch(
                    setLoginFailure({
                        isAPIRequestRunning: false,
                        errors: errors
                    })
                );

                // DEVELOPERS NOTE:
                // IF A CALLBACK FUNCTION WAS SET THEN WE WILL RETURN THE JSON
                // OBJECT WE GOT FROM THE API.
                if (failedCallback) {
                    failedCallback(errors, exception.response.status);
                }
            }
        }).then( () => {
            // Do nothing.
        });
    }
}

export const setLogoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
    payload: {
        isAPIRequestRunning: false,
        data: {},
        errors: {}
    },
});


export function postLogout() {
    return dispatch => {
        // Change the global state to attempting to log in.
        store.dispatch(
            setLogoutSuccess()
        );
    }
}
