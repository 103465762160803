/**
 *  THE PURPOSE OF THIS HELPER IS TO PROVIDE THE ABILITY TO ITERATE OVER ALL
 *  THE TEST RESOURCES (CONTEXT, STATEMENT & OPTION) AND PRELOAD THE IMAGES
 *  INTO THE BROWSERS CACHE.
 *
 *  SPECIAL THANKS:
 *  - https://stackoverflow.com/a/50227675
 */
import { IMAGE_ELEMENT_TYPE, HTML_ELEMENT_TYPE } from "../constants/states";


export function preloadImagesFromTest(test) {
    for (let session of test.sessions) {
        for (let question of session.questions) {
            /*
             * CONTEXT
             */
            if (question.context) {
                question.context.questionContexts.forEach((questionContext) => {
                    if (questionContext.type===IMAGE_ELEMENT_TYPE) {
                        preloadImagesFromImageContent(questionContext.content);
                    }
                    if (questionContext.type===HTML_ELEMENT_TYPE) {
                        preloadImagesFromHTMLContent(questionContext.content);
                    }
                });
            }

            /*
             * QUESTIONS
             */
            question.statements.forEach((statement) => {
                if (statement.type===IMAGE_ELEMENT_TYPE) {
                    preloadImagesFromImageContent(statement.content);
                }
                if (statement.type===HTML_ELEMENT_TYPE) {
                    preloadImagesFromHTMLContent(statement.content);
                }
            });

            /*
             * STATEMENTS
             */
            question.options.forEach((option) => {
                if (option.type===IMAGE_ELEMENT_TYPE) {
                    preloadImagesFromImageContent(option.content);
                }
                if (option.type===HTML_ELEMENT_TYPE) {
                    preloadImagesFromHTMLContent(option.content);
                }
            });
        }
        console.log("\n\n");
    }
}

function preloadImagesFromImageContent(content) {
    const img = new Image();
    img.src = content;
    window[img] = img;
    console.log("preloadImagesFromImageContent | Cached Option:", content);
}

function preloadImagesFromHTMLContent(content) {
    // Create a new div element
    var temporalDivElement = document.createElement("div");

    // Set the HTML content with the providen
    temporalDivElement.innerHTML = content;

    var images = temporalDivElement.getElementsByTagName('img');
    var srcList = [];
    for(var i = 0; i < images.length; i++) {
        srcList.push(images[i].src);
    }

    for (let src of srcList) {
        const img = new Image();
        img.src = src;
        window[img] = img;
        console.log("preloadImagesFromHTMLContent | Cached Option:", src);
    }
}
