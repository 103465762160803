import React, { Component } from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import {
    Table,
    TableHeader,
    TableCell,
    TableBody,
    DataTableCell
} from "@david.kucsai/react-pdf-table";
// import QRCode from 'qrcode' // `npm install --save qrcode`


const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        height:"100%"
    },
    section: {
        marginLeft: 2,
        padding: 3,
        flexGrow: 1,
        marginRight:14,
    },
    image_left: {
        height: 40,
        width: 40.05,
        marginLeft: 9,
    },
    image_right: {
        height: 30,
        width: 64.05,
        position:"absolute",
        right: "-6px",
        marginTop:7,
        marginRight: 0
    },
    div_table : {
        width:"100%",
        margin: 7,
        padding: 2,
        borderRadius: 15,
        border: "1 solid #c4c4c4",
        padding:"1%",
    },
    code_box : {
      width:"100%",
      margin: 7,
      padding: 8,
      borderRadius: 4,
      border: "1 solid #c4c4c4",
      backgroundColor: "#ededed",
    },
    header_4 : {
        margin: "auto",
        fontSize:9,
        marginBottom: 8,
    },
    text_1 : {
        fontSize: 8,
        fontWeight:900,
        marginTop:30,
        padding:6
    },
    body_elements_left : {
        fontWeight : 900,
        fontSize:8,
        marginLeft : 7,
        // fontFamily: "Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif",
    },
    body_elements_right : {
        fontWeight : 900,
        fontSize:8,
        marginLeft : "50%",
        // fontFamily: "Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif",
    },
    table_cell : {
        display: "table-cell",
        textAlign: "center",
        backgroundColor: "#14405B",
        color: "#FFF",
        fontSize:9,
        borderRight:"1px solid #FFF",
        padding:3,
        fontWeight : 900,
    },
    table_header : {
        borderColor:"#FFF"
    },
    table_body : {
        marginTop: 100,
        border : "#FFF"
    },
    data_table_cell : {
        margin: 3,
        fontSize:8,
        border : "#FFF"
    },
    footer : {
        fontSize:8,
        marginTop: 5,
        marginLeft : 7,
    },
    bottom_line_number : {
      fontSize:9,
      fontWeight: 900
    },
    footer_img : {
        width:"35%",
        height: 17,
        marginLeft:"29%",
        marginTop:"2%"
    }
});

/**
 *  The purpose of this component is to provide the graphics for the PDF
 *  document to be created using the `react-pdf` library.
 */
export default function ReceiptPdfDocumentComponent(props) {
    console.log("DEBUGGING", props.data); // Useful code for debugging purposes only.
    const {
        logoRightURL, logoLeftURL, footerURL, studentName, studentId, grade,
        collegeCode, city, state, startedAt, finishedAt, duration, test,
        answers, uploadContent, isLate, status
    } = props.data;
    const {
        name,
    } = test;

    // // The following code will block the main runtime loop to generate the QR
    // // code in the background and then unblock the main runtime loop once the
    // // QR code has been generated. See: https://github.com/soldair/node-qrcode.
    // let qrCodeURL = "";
    // let isRunning = true;
    // while (isRunning) {
    //     QRCode.toDataURL(uploadContent, function (err, url) {
    //         qrCodeURL = url;
    //         isRunning = false;
    //         console.log(err);
    //     });
    // }

    console.log("Rending PDF Data:", props.data);

    // Render the GUI of our PDF using the `@react-pdf/renderer` library.
    return (
        <Document>
            {/*
                SPECIAL THANKS TO THE FOLLOWING:
                https://react-pdf.org/
                https://dev.to/finallynero/generating-pdf-documents-in-react-using-react-pdf-4ka7
            */}
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image
                        style={styles.image_left}
                        source={logoRightURL}
                    />
                    <Image
                        style={styles.image_right}
                        source={logoLeftURL}
                    />
                    <View style={styles.div_table}>
                        <Text className="body" style={styles.header_4}>Información del Estudiante</Text>
                        <Text className="body" style={styles.body_elements_left}>Nombres: {studentName}</Text>
                        <Text className="body" style={styles.body_elements_right}>Usuario: {studentId}</Text>
                        <Text className="body" style={styles.body_elements_left}>Grado: {grade}</Text>
                        <Text className="body" style={styles.body_elements_right}>Colegio: {collegeCode}</Text>
                        <Text  className="body"style={styles.body_elements_left}>Ciudad: {city}</Text>
                    </View>
                    <View style={styles.div_table}>
                        <Text style={styles.header_4}>Información de la Prueba</Text>
                        <Text style={styles.body_elements_left}>Prueba: {name}</Text>
                        <Text style={styles.body_elements_right}>Estado:&nbsp;
                            {/*
                                DEVELOPERS NOTE:
                                (1) See `constants/states.js` file for latest details.
                                (2) Here is a snippet from it.
                                ----------------------------------------------------
                                export const TEST_SUBMISSION_PENDING_STATE = 1
                                export const TEST_SUBMISSION_IN_PROGRESS_STATE = 2
                                export const TEST_SUBMISSION_BREAK_STATE = 3
                                export const TEST_SUBMISSION_COMPLETED_STATE = 4
                                export const TEST_SUBMISSION_INCOMPLETED_STATE = 5
                                export const TEST_SUBMISSION_ABANDONED_STATE = 6
                                ----------------------------------------------------
                            */}
                            {status === 4 && isLate === true && "Completado con tiempo agotado"}
                            {status === 4 && isLate === false && "Terminar"}
                            {status === 6 && "Abandonar"}
                        </Text>
                        <Text style={styles.body_elements_left}>Fecha y hora Inicio: {startedAt}</Text>
                        <Text style={styles.body_elements_right}>Fecha y hora Fin: {finishedAt}</Text>
                        <Text style={styles.body_elements_left}>Duración Total: {duration}</Text>

                        {/*<Text style={null}>Promedio por respuesta: [average for answer]</Text>*/}
                        <View style={{marginTop:25}}>
                        <Table
                            data={answers}
                            includeTopBorder={false}>
                            <TableHeader style={styles.table_header}
                                includeBottomBorder={false}
                                includeTopBorder={false}
                                includeRightBorder={false}
                                includeLeftBorder={false}>
                                <TableCell style={styles.table_cell}>
                                    Materia
                                </TableCell>
                                <TableCell style={styles.table_cell}>
                                    Pregunta
                                </TableCell>
                                <TableCell style={styles.table_cell}>
                                    Respuesta (*)
                                </TableCell>
                                <TableCell style={styles.table_cell}>
                                    Hora Inicio
                                </TableCell>
                                <TableCell style={styles.table_cell}>
                                    Hora Fin
                                </TableCell>
                                <TableCell style={styles.table_cell}>
                                    Duración
                                </TableCell>
                            </TableHeader>
                            <TableBody
                                includeBottomBorder={false}
                                includeTopBorder={false}
                                includeRightBorder={false}
                                includeLeftBorder={false}>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.subjectName}/>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.order}/>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.choice}/>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.startAt}/>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.endAt}/>
                                <DataTableCell style={styles.data_table_cell} getContent={(r) => r.duration}/>
                            </TableBody>
                        </Table>
                        </View>
                    </View>

                    {/*
                        https://github.com/dmk99/react-pdf-table
                    */}

                    <Text style={styles.footer}>1- (*) La plataforma usa la aleatoriedad de respuestas como mecanismo de reducción de fraude. Por ello, la información que aparece fuera del paréntesis () en la columna “Respuesta”, corresponde a una máscara asignada por la plataforma y seleccionada por el estudiante durante la aplicación. La letra dentro del paréntesis, corresponde a la opción real seleccionada durante la aplicación y en ningún caso a la respuesta correcta de la pregunta.</Text>
                    <Text style={styles.footer}>Recuerda que tus resultados serán consultados en <Text style={styles.bottom_line_number}><a href="https://www.miltonochoa.com">www.miltonochoa.com</a></Text> 8 días después de la aplicación.</Text>
                    <Text style={styles.footer}>Si tienes alguna novedad e inquietud puedes comunicarte con nuestra línea de whatsapp de atención al cliente <Text style={styles.bottom_line_number}><a href="tel:3164723787">316 472 37 87</a></Text> o envíalo a nuestro correo electrónico <Text style={styles.bottom_line_number}><a href="mailto:atencionalcliente@ceinfes.com">atencionalcliente@ceinfes.com</a></Text></Text>
                    <Image
                        style={styles.footer_img}
                        source={footerURL}
                    />
                </View>
            </Page>

            {/*
                The following code will generate a QR code, unfortunately some
                of our uploaded content is too large (see: https://stackoverflow.com/q/11065415).
            */}
            {/*
            {qrCodeURL &&
                <Page size="A4" style={styles.page}>
                <View style={null}>
                    <Image
                        style={null}
                        source={qrCodeURL}
                    />

                </View>
                </Page>
            }
            */}

            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={{textDecoration:"underline",textTransform: "uppercase",textAlign:"center",fontSize:9,marginTop:23 }}>Uso exclusivo de la empresa</Text>
                    <Text style={styles.text_1}>El código generado a continuación se encuentra cifrado y es para uso exclusivo de Asesorías Académicas Milton Ochoa con el objetivo de verificar la aplicación de tu prueba.</Text>
                    <View style={styles.code_box}>
                        <Text style={{fontSize:6}}>{uploadContent}</Text>
                    </View>
                </View>
            </Page>

        </Document>
    );
}
