import thunk from 'redux-thunk';
import  { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { APP_STATE } from "./constants/redux";
import {
    TEST_DETAIL_SUCCESS,
    TEST_SUBMISSION_TIME_SYNC_REQUEST,
    PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS,
    LOGOUT_SUCCESS,
} from "./constants/actionTypes";
import { testDetailReducer} from "./reducers/testReducers";
import testSubmissionReducer from "./reducers/testSubmissionReducers";


// Combine Reducers
const appReducer = combineReducers({
    testSubmissionState: testSubmissionReducer,
    testState: testDetailReducer,
});


/**
 *  Reducer to be used before the "appReducer" used. The difference with is
 *  this reducer will clear the `redux` state if the logout state was detected.
 *
 *  Special thanks to:
 *  https://stackoverflow.com/a/35641992
 */
const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        state = undefined
    }
    return appReducer(state, action)
}


/**
 *  Function will save the application state if a specific 'react-redux' state
 *  was triggered.
 *
 *  Special thanks: https://stackoverflow.com/a/52593860
 */
const localStorageMiddleware = ({ getState }) => {
    return next => action => {
        const result = next(action);
        if ([
            TEST_DETAIL_SUCCESS,
            TEST_SUBMISSION_TIME_SYNC_REQUEST,
            PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_SUCCESS,
            LOGOUT_SUCCESS
        ].includes(result.type)) {
            // console.log("De-hydrating store...");
            localStorage.setItem(APP_STATE, JSON.stringify(getState()))
        }
        return result;
    };
};


/**
 *  Function will load up the saved app-state from the local storage on
 *  application initial startup.
 *
 *  Special thanks: https://stackoverflow.com/a/52593860
 */
const reHydrateStore = () => {
    const data = localStorage.getItem(APP_STATE);
    if (data) {
        // console.log("Re-hydrating Store...");
        const jsonData = JSON.parse(data);
        // console.log("Store Contents:", jsonData); // For debugging purposes only.
        return jsonData;
    }
    return undefined;
};


// Create our store singleton object and populate it with our initial data.
const store = createStore(
    rootReducer,
    reHydrateStore(),
    composeWithDevTools(
        applyMiddleware(
            thunk,
            localStorageMiddleware
        )
    )
);


export default store;
