import React from "react";
import Image from 'react-bootstrap/Image';

import {
    HTML_ELEMENT_TYPE,
    TEXT_ELEMENT_TYPE,
    IMAGE_ELEMENT_TYPE
} from "../../../constants/states";


export default function StatementIteratorComponent({order, statements}) {
    let elements = statements.map(
        (statement, i) => (
            <li key={i}>
                <div key={statement.slug}>
                    {statement.type === TEXT_ELEMENT_TYPE &&
                        <div>{statement.content}</div>
                    }

                    {statement.type === HTML_ELEMENT_TYPE &&
                        <div dangerouslySetInnerHTML={{__html: statement.content}} />
                    }

                    {statement.type === IMAGE_ELEMENT_TYPE &&
                        <Image fluid src={statement.content} alt={"statement image"} />
                    }
                </div>
            </li>
        )
    );
    return (
        <div>
            <ol style = {{listStyleType: "none", padding: 0}}>
                {elements}
            </ol>
        </div>
    )
}
