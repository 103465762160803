import React, { Component } from "react";

import NotFound404Component from "../../components/general/notFound404Component";


export default class NotFound404Container extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <NotFound404Component />
        );
    }
}
