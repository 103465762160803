import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import footer from '../../img/footer.png';

function Footer() {
    return (
        <Row className="justify-content-center mt-3 mb-3">
            <Col xs={8} md={5} lg={4}>
                <Image src={footer} fluid />
            </Col>
        </Row>
    );
}

export default Footer;
