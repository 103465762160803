import isEmpty from 'lodash/isEmpty';
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Hotkeys from 'react-hot-keys';

import TestSubmissionAnswerComponent from "../../components/testSubmission/answerComponent/baseComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import getOrCreateRandomizeQuestion from "../../helpers/randomizeQuestionChoiceHelper";
import {
    TEST_SUBMISSION_BREAK_STATE,
    TEST_SUBMISSION_COMPLETED_STATE,
    TEST_SUBMISSION_ABANDONED_STATE
} from "../../constants/states";
import { postAbandonTestSubmission } from "../../actions/testSubmissionActions";
import getBrowserMetrics from '../../helpers/browserMetricsHelper';
import { localStorageDump } from '../../helpers/localStorageUtility';


class TestSubmissionAnswerContainer extends Component {

    /**
     *  Initializer
     *------------------------------------------------------------
     */

    constructor(props) {
        super(props);

        // For diagnostic purposes, attach some browser details to help the
        // programmers diagnose problems pertaining to test submissions.
        let browserMetrics = getBrowserMetrics();

        const evaluatorStore = getEvaluatorStoreInstance();

        this.state = {
            evaluatorStore: evaluatorStore,
            errors: {},
            answerChoiceSlug: evaluatorStore.getPickedAnswerSlug(),
            startCountdownTimerAtTimestamp: evaluatorStore.getStartCountdownTimerAtTimestamp(),
            questionObj: evaluatorStore.getCurrentQuestion(),
            testSessionObj: evaluatorStore.getCurrentTestSession(),
            isLoading: false,
            forceURL: "",
            showAbandonModal: false,
            showInfoModal: false,
            browserMetrics: browserMetrics,
        };
        this.onChoiceClick = this.onChoiceClick.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.onCountdownTimerTick = this.onCountdownTimerTick.bind(this);
        this.onCountdownTimerCompletion = this.onCountdownTimerCompletion.bind(this);
        this.onAbandonTestSubmissionClick = this.onAbandonTestSubmissionClick.bind(this);
        this.onAbandonOKClick = this.onAbandonOKClick.bind(this);
        this.onAbandonCloseClick = this.onAbandonCloseClick.bind(this);
        this.onInfoTestSubmissionClick = this.onInfoTestSubmissionClick.bind(this);
        this.onInfoOKClick = this.onInfoOKClick.bind(this);
        this.onInfoCloseClick = this.onInfoCloseClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onPOSTSuccessCallback = this.onPOSTSuccessCallback.bind(this);
        this.onPOSTFailedCallback = this.onPOSTFailedCallback.bind(this);
    }

    /**
     *  Utility
     *------------------------------------------------------------
     */

    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page.

        // The following code ensures that when the user finishes loading
        // this React component then we will check to see if the time ran
        // out and if it did then we need to stop this test and mark is as
        // late. Note: Negative value from `getRemainingTimeInMinutes`
        // function means finished!
        const remainingTimeInMinutes = this.state.evaluatorStore.getRemainingTimeInMinutes();
        if (remainingTimeInMinutes < 0) {
            this.onCountdownTimerCompletion();
        }
    }

    componentDidUpdate(nextProps) {}

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */

    onPOSTSuccessCallback(test) {
        console.log("TestSubmissionAnswerContainer | onPOSTSuccessCallback | Log");
        console.log(test, "\n\n");
        this.state.evaluatorStore.setWasSubmittedToAPI(true);

        // Redirected to the last page.
        this.setState({
            isLoading: false,
            errors: {},
            forceURL: "/submission/completed"
        });
    }

    onPOSTFailedCallback(errors, status) {
        console.log("TestSubmissionAnswerContainer | Log\n");
        console.log("onPOSTFailedCallback |", errors, "|", status,"\n\n");

        if (status === 500) {
            // this.setState({ isLoading: false, errors: {
            //     // A server error occurred when submitting the test, please try again later.
            //     'nonFieldError': '¡Ha ocurrido un error al enviar la prueba! Vuelve a intentarlo más tarde.',
            // }, });
            this.props.history.push("/500");
        }
        else if (status === 400) {
            this.props.history.push("/400?errorId=0&errorMessage="+errors);
            return;
        } else if (status === 403) {
            this.setState({ isLoading: false, errors: {
                // Your session has expired, please log in before submitting your test.
                'nonFieldError': "¡Tu sesión ha expirado! Inicia sesión para enviar tus resultados",
            }, });
        } else {
            this.setState({ isLoading: false, errors: {
                // An unknown error has occured, please contact the system administrator.
                'nonFieldError': "¡Ha ocurrido un error desconocido! Ponte en contacto con el administrador del sistema.",
            }, });
        }
    }

    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    /**
     *  Callback function will be called by 'react-countdown' component when
     *  the timer runs out. This functino will:
     *  (1) Automatically post to API latest answers.
     *  (2) Mark test complete and late.
     *  (3) Redirect user to completion page.
     */
    onCountdownTimerCompletion() {
        console.log("TestSubmissionAnswerContainer | onCountdownTimerCompletion\n\n");

        /*
         *  Run the code block which will process the transition to our
         *  new state and return the state we are going to be in.
         */
        const newStatus = this.state.evaluatorStore.tickOnComplete();

        /*
         * Handle two cases:
         * (1) If user is going to finish.
         * (2) If user is going on break.
         */
        if (newStatus === TEST_SUBMISSION_COMPLETED_STATE) {
            // IMPORTANT: Indicate that student did not finish this test in time.
            this.state.evaluatorStore.setIsLate(true);

            // Redirected to the sending page.
            this.setState({
                isLoading: false,
                errors: {},
                forceURL: "/submission/sending"
            });

        } else if (newStatus === TEST_SUBMISSION_BREAK_STATE) {
            this.setState({ forceURL: "/submission/break" });
        }
    }

    onCountdownTimerTick() {
        const timeRemaining = this.state.evaluatorStore.getRemainingTimeInMinutes();
        console.log("Time Remaining (in minutes):", timeRemaining, "\n\n");
    }

    onFinalSubmitClick() {
        // Redirected to the last page.
        this.setState({
            isLoading: false,
            errors: {},
            forceURL: "/submission/sending"
        });
    }

    onSubmitClick(event) {
        event.preventDefault();
        window.scrollTo(0, 0);

        const { evaluatorStore } = this.state;
        if (this.state.answerChoiceSlug === "" || this.state.answerChoiceSlug === null) {
            this.setState({
                errors: {
                    'answerChoiceSlug': 'Need to select.'
                }
            });
            return;
        }
        if (evaluatorStore.isLastQuestionInSession() === false) {
            this.setState({ isLoading: true, errors: {}, },()=>{
                evaluatorStore.startNextQuestion();
                this.setState({
                    isLoading: false,
                    errors: {},
                    answerChoiceSlug: "",
                    questionObj: evaluatorStore.getCurrentQuestion(),
                });
            });
        } else {
            evaluatorStore.startNextQuestion();
            this.setState({
                isLoading: true,
                errors: {},
                answerChoiceSlug: "",
                questionObj: {},
            });

            /*
             *  Developers note:
             *  Run the code which will load up either the finish page,
             *  or break page depending on the test configuration.
             */
             console.log("TestSubmissionAnswerContainer | onSubmitClick\n\n");

             /*
              *  Run the code block which will process the transition to our
              *  new state and return the state we are going to be in.
              */
             const newStatus = this.state.evaluatorStore.tickOnComplete();

             /*
              *  Redirect to the correct page based on the state.
              */
             if (newStatus === TEST_SUBMISSION_COMPLETED_STATE) {
                this.onFinalSubmitClick();
             } else if (newStatus === TEST_SUBMISSION_BREAK_STATE) {
                 this.setState({ forceURL: "/submission/break" });
             }
        }
    }

    onChoiceClick(event, optionSlug, optionLetter, optionPsuedoLetter) {
        event.preventDefault();
        this.setState({
            answerChoiceSlug: optionSlug,
            answerChoiceLetter: optionLetter,
        }, ()=> {
            console.log("TestSubmissionAnswerContainer | onChoiceClick | Log");
            console.log("Slug:", optionSlug, "\n");
            console.log("Letter:", optionLetter, "\n\n");
            this.state.evaluatorStore.setAnswer(optionSlug, optionLetter, optionPsuedoLetter,);
        });
    }

    onAbandonTestSubmissionClick(event) {
        event.preventDefault();
        this.setState({
            showAbandonModal: !this.state.showAbandonModal,
        });
    }

    onAbandonOKClick(event) {
        this.setState({
            showAbandonModal: !this.state.showAbandonModal
        },()=>{
            /*
             *  Run the code block which will process the transition to our
             *  new state and return the state we are going to be in.
             */
            this.state.evaluatorStore.tickOnComplete();

            /*
             *  Change the state of the test submission to be `abandoned` and
             *  save the model.
             */
            this.state.evaluatorStore.overrideStatus(TEST_SUBMISSION_ABANDONED_STATE);

            // Submit the new state.
            this.props.postAbandonTestSubmission(
                {
                    testSubmissionUuid: this.state.evaluatorStore.getTestSubmissionUuid(),
                    answers: this.state.evaluatorStore.getUploadableContent(),
                    browserMetrics: this.state.browserMetrics,
                },
                this.onPOSTSuccessCallback,
                this.onPOSTFailedCallback
            );
        });
    }

    onAbandonCloseClick(event) {
        this.setState({
            showAbandonModal: !this.state.showAbandonModal
        });
    }

    onInfoTestSubmissionClick(event) {
        event.preventDefault();
        this.setState({
            showInfoModal: !this.state.showInfoModal,
        });
    }

    /**
     *  Function will take the submission data, encode it in base64 string,
     *  and create a text file for the user to download for their archiving
     *  purposes.
     */
    onInfoOKClick(event) {
        this.setState({
            showInfoModal: !this.state.showInfoModal
        },()=>{
            const lsDictDump = localStorageDump();

            // Special thanks:
            // Base64 encode a javascript object via https://stackoverflow.com/a/38134388
            let objJsonStr = JSON.stringify(lsDictDump);
            let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

            // For debugging purposes only.
            console.log(objJsonB64);
            console.log(lsDictDump);

            // Mapping of names.
            const base64String = objJsonB64;

            // Special thanks to:
            // "Text File Download in React" via https://medium.com/@kayathiri/text-file-download-in-react-a8b28a580c0d
            const element = document.createElement("a");
            const file = new Blob([base64String], {type: 'text/plain;charset=utf-8'});
            element.href = URL.createObjectURL(file);
            element.download = "snapshot.txt";
            document.body.appendChild(element);
            element.click();
        });
    }

    onInfoCloseClick(event) {
        this.setState({
            showInfoModal: !this.state.showInfoModal
        });
    }

    onKeyUp(keyName, e, handle) {
        console.log("test:onKeyUp", e, handle)
        this.setState({
            showInfoModal: !this.state.showInfoModal
        });
    }

    onKeyDown(keyName, e, handle) {
        console.log("test:onKeyDown", keyName, e, handle)
        // this.setState({
        //     showInfoModal: !this.state.showInfoModal
        // });
    }


    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const {
            questionObj, testSessionObj, answerChoiceSlug, errors, isLoading,
            startCountdownTimerAtTimestamp, forceURL, showAbandonModal, showInfoModal,
        } = this.state;

        /**
         *  If the countdown timer ran out or the user finished before the
         *  countdown timer finshes then redirect to a different page.
         */
        if (forceURL !== undefined && forceURL !== null && forceURL !== "") {
            return <Redirect to={forceURL} />;
        }

        /**
         *  Developers note:
         *  We want to present the question re-arranged in a random manner so
         *  students won't be able to cheat. We will simply present a random order
         *  but behind the scenes keep the order presented from the API.
         */
        const randomizedQuestionObj = isLoading ? null : getOrCreateRandomizeQuestion(questionObj);

        return (
            <Hotkeys
                keyName="ctrl+s"
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
            >
                <TestSubmissionAnswerComponent
                    testSubmissionObj={this.props.testSubmission}
                    questionObj={randomizedQuestionObj}
                    testSessionObj={testSessionObj}
                    answerChoiceSlug={answerChoiceSlug}
                    startCountdownTimerAtTimestamp={startCountdownTimerAtTimestamp}
                    isLoading={isLoading}
                    errors={errors}
                    onCountdownTimerCompletion={this.onCountdownTimerCompletion}
                    onCountdownTimerTick={this.onCountdownTimerTick}
                    onSubmitClick={this.onSubmitClick}
                    onChoiceClick={this.onChoiceClick}
                    onAbandonTestSubmissionClick={this.onAbandonTestSubmissionClick}
                    showAbandonModal={showAbandonModal}
                    onAbandonCloseClick={this.onAbandonCloseClick}
                    onAbandonOKClick={this.onAbandonOKClick}
                    onInfoTestSubmissionClick={this.onInfoTestSubmissionClick}
                    showInfoModal={showInfoModal}
                    onInfoCloseClick={this.onInfoCloseClick}
                    onInfoOKClick={this.onInfoOKClick}
                />
            </Hotkeys>
        );
    }
}


const mapStateToProps = function(store) {
    return {
        testObj: store.testState,
        testSubmissionObj: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        postAbandonTestSubmission: (data, onPOSTSuccessCallback, onPOSTFailedCallback) => {
            dispatch(postAbandonTestSubmission(data, onPOSTSuccessCallback, onPOSTFailedCallback))
        },
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestSubmissionAnswerContainer);
