import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import BreakComponent from "../../components/testSubmission/breakComponent";
import getEvaluatorStoreInstance from "../../dataStores/evaluatorStore";
import {
    TEST_SUBMISSION_IN_PROGRESS_STATE,
    TEST_SUBMISSION_COMPLETED_STATE
} from "../../constants/states";


class BreakContainer extends Component {

    /**
     *  Initializer
     *------------------------------------------------------------
     */

    constructor(props) {
        super(props);

        const evaluatorStore = getEvaluatorStoreInstance();

        this.state = {
            evaluatorStore: evaluatorStore,
            errors: {},
            startCountdownTimerAtTimestamp: evaluatorStore.getStartCountdownTimerAtTimestamp(),
            testBreakObj: evaluatorStore.getCurrentBreak(),
            forceURL: "",
        };

        this.onCountdownTimerCompletion = this.onCountdownTimerCompletion.bind(this);
        this.onCountdownTimerTick = this.onCountdownTimerTick.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    /**
     *  Utility
     *------------------------------------------------------------
     */



    /**
     *  Component Life-cycle Management
     *------------------------------------------------------------
     */

    componentDidMount() {
        window.scrollTo(0, 0);  // Start the page at the top of the page.
    }

    componentWillUnmount() {
        // This code will fix the "ReactJS & Redux: Can't perform a React state
        // update on an unmounted component" issue as explained in:
        // https://stackoverflow.com/a/53829700
        this.setState = (state,callback)=>{
            return;
        };
    }

    /**
     *  API callback functions
     *------------------------------------------------------------
     */



    /**
     *  Event handling functions
     *------------------------------------------------------------
     */

    onClick() {
        this.onCountdownTimerCompletion();
    }

    /**
     *  Callback function will be called by 'react-countdown' component when
     *  the timer runs out.
     */
    onCountdownTimerCompletion() {
        console.log("TestSubmissionAnswerContainer | onCountdownTimerCompletion\n\n");

        /*
         *  Run the code block which will process the transition to our
         *  new state and return the state we are going to be in.
         */
        const newStatus = this.state.evaluatorStore.tickOnComplete();

        /*
         *  Redirect to the correct page based on the state.
         */
        if (newStatus === TEST_SUBMISSION_COMPLETED_STATE) {
            this.setState({ forceURL: "/submission/completed" });
        } else if (newStatus === TEST_SUBMISSION_IN_PROGRESS_STATE) {
            this.setState({ forceURL: "/submission/question" });
        }
    }

    onCountdownTimerTick() {

    }

    /**
     *  Main render function
     *------------------------------------------------------------
     */

    render() {
        const {
            forceURL, testBreakObj, startCountdownTimerAtTimestamp, isLoading,
            errors
        } = this.state;

        /**
         *  If the countdown timer ran out or the user finished before the
         *  countdown timer finshes then redirect to a different page.
         */
        if (forceURL !== undefined && forceURL !== null && forceURL !== "") {
            return <Redirect to={forceURL} />;
        }

        return (
            <BreakComponent
                testBreakObj={testBreakObj}
                startCountdownTimerAtTimestamp={startCountdownTimerAtTimestamp}
                onCountdownTimerCompletion={this.onCountdownTimerCompletion}
                onCountdownTimerTick={this.onCountdownTimerTick}
                testSubmissionObj={this.props.testSubmission}
                isLoading={isLoading}
                errors={errors}
                onClick={this.onClick}
            />
        );
    }
}


const mapStateToProps = function(store) {
    return {
        user: store.userState,
        testSubmission: store.testSubmissionState,
    };
}

const mapDispatchToProps = dispatch => {
    return {}
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BreakContainer);
