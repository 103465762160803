import React, { Component } from 'react';
import Image from 'react-bootstrap/Image';

import {
    HTML_ELEMENT_TYPE,
    TEXT_ELEMENT_TYPE,
    IMAGE_ELEMENT_TYPE
} from "../../../constants/states";


export default function OptionIteratorComponent({ options, onChoiceClick, answerChoiceSlug }) {
    let elements = options.map(
        (option) => (
            <div
                className="custom-control custom-radio"
                key={`${option.slug}-option-choice`}
                onClick={ (event, slug, letter, psuedoLetter)=>{ onChoiceClick(event, option.slug, option.optionText, option.psuedoOptionLetter) } }>

                <input
                    checked={answerChoiceSlug === option.slug}
                    type="radio"
                    className="custom-control-input"
                    id={option.optionId}
                    name="Options"
                    onChange={ (event, slug, letter)=>{ onChoiceClick(event, option.slug, option.optionText) } }
                />

                <label className="custom-control-label" htmlFor={option.optionId}>
                    <b>{option.psuedoOptionLetter}{"."}&nbsp;</b>
                    {option.type === TEXT_ELEMENT_TYPE &&
                        <span className="text-dark">{option.content}</span>
                    }
                    {option.type === IMAGE_ELEMENT_TYPE &&
                        <div className="text-dark">
                            <Image fluid src={option.content} alt={"option image"} />
                        </div>
                    }
                    {option.type === HTML_ELEMENT_TYPE &&
                        <div className="text-dark" dangerouslySetInnerHTML={{__html: option.content}} />
                    }
                </label>
            </div>
        )
    )
    return elements;
}
