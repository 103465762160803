import {
    TEST_DETAIL_REQUEST, TEST_DETAIL_FAILURE, TEST_DETAIL_SUCCESS
} from '../constants/actionTypes';


export const testDetailReducer = function(state = [], action = {}) {
    switch (action.type) {
        case TEST_DETAIL_REQUEST:
            return Object.assign({}, state, action.payload);

        case TEST_DETAIL_FAILURE:
            return Object.assign({}, state, action.payload);

        case TEST_DETAIL_SUCCESS:
            return Object.assign({}, state, action.payload);

        default:
            return state;
    }
}
