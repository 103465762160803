// states.js
// This file is responsible for keeping a list of state values used in our
// backend API endpoints.

/**
 * Test Submission
 * https://github.com/London-Language-Institute/markingcloud-back/blob/master/markingcloud/tenant_foundation/models/test_submission.py
 */
export const TEST_SUBMISSION_PENDING_STATE = 1
export const TEST_SUBMISSION_IN_PROGRESS_STATE = 2
export const TEST_SUBMISSION_BREAK_STATE = 3
export const TEST_SUBMISSION_COMPLETED_STATE = 4
export const TEST_SUBMISSION_INCOMPLETED_STATE = 5 //TODO: Implement.
export const TEST_SUBMISSION_ABANDONED_STATE = 6

/**
 * Test Element Type
 * https://github.com/London-Language-Institute/markingcloud-back/blob/master/markingcloud/tenant_foundation/models/test_type_element.py
 */
export const HTML_ELEMENT_TYPE = "Html";
export const TEXT_ELEMENT_TYPE = "Text";
export const IMAGE_ELEMENT_TYPE = "Image";

/**
 *  Test Answer View
 *  https://github.com/London-Language-Institute/markingcloud-back/blob/master/markingcloud/tenant_foundation/models/test_answer_view.py
 */
export const VERTICAL_ANSWER_VIEW = "Vertical";
export const HORIZONTAL_ANSWER_VIEW = "Horizontal";
export const TWO_BY_TWO_ANSWER_VIEW = "2x2";


/**
 *  Coundown Timer States
 *  https://github.com/London-Language-Institute/markingcloud-back/blob/master/markingcloud/tenant_foundation/models/test_submission_countdown_timer.py
 *  https://github.com/London-Language-Institute/markingcloud-back/blob/master/markingcloud/tenant_foundation/models/test_submission_operation_timer.py
 */
export const COUNTDOWN_TIMER_PAUSED_STATE = 1;
export const COUNTDOWN_TIMER_RUNNING_STATE = 2;
