/**
 *  The constant used for hydrating/re-hydrating the redux state.
 */
export const APP_STATE = 'APP_STATE';

/**
 *  The base URL to apply to all the API endpoints.
 */
export const MCL_API_BASE_PATH = '/en/api'

export const MCL_PASSWORDLESS_LOGIN_FROM_LAUNCHPAD_API_ENDPOINT = '/v1/colombia/account/login-from-launchpad';
export const MCL_TEST_DETAIL_API_ENDPOINT = '/v1/colombia/test/<testSubmissionUuid>';
export const MCL_TEST_SUBMISSION_DETAIL_API_ENDPOINT = '/v1/colombia/test-submission/<testSubmissionUuid>';
export const MCL_ABANDON_TEST_SUBMISSION_CREATE_API_ENDPOINT = '/v1/colombia/test-submission/<testSubmissionUuid>/abandon';
export const MCL_BEGIN_TEST_SUBMISSION_CREATE_API_ENDPOINT = '/v1/colombia/test-submission/<testSubmissionUuid>/begin';
export const MCL_TEST_SUBMISSION_CREATE_API_ENDPOINT = '/v1/colombia/test-submission/<testSubmissionUuid>';
